export const HOST = "https://vg.ipsodia.com"
const BACKEND_PORT = "8000"

export const ENV_PORT = "2000";

export const API_URL = HOST + '/';

// All getters and setters functions
export const getPageIsLoaded = () => {
  return JSON.parse(localStorage.getItem("pageIsLoaded")) || [];
};

export const setPageIsLoaded = (value) => {
  localStorage.setItem("pageIsLoaded", JSON.stringify(value));
};

export const getProductPhotos = () => {
  return JSON.parse(localStorage.getItem("productPhotos")) || [];
};

export const setProductPhotos = (value) => {
  localStorage.setItem("productPhotos", JSON.stringify(value));
};

export const getProductExtraPhotos = () => {
  return JSON.parse(localStorage.getItem("productExtraPhotos")) || [];
};

export const setProductExtraPhotos = (value) => {
  localStorage.setItem("productExtraPhotos", JSON.stringify(value));
};

export const getProductExtraPhotoData = () => {
  return JSON.parse(localStorage.getItem("productExtraPhotoData")) || [];
};

export const setProductExtraPhotoData = (value) => {
  localStorage.setItem("productExtraPhotoData", JSON.stringify(value));
};

export const getAllProducts = () => {
  return JSON.parse(localStorage.getItem("allProducts")) || [];
};

export const setAllProducts = (value) => {
  localStorage.setItem("allProducts", JSON.stringify(value));
};

export const getResultProducts = () => {
  return JSON.parse(localStorage.getItem("resultProducts")) || [];
};

export const setResultProducts = (value) => {
  localStorage.setItem("resultProducts", JSON.stringify(value));
};

export const getProductData = () => {
  return JSON.parse(localStorage.getItem("productData")) || [];
};

export const setProductData = (value) => {
  localStorage.setItem("productData", JSON.stringify(value));
};

export const getProductSpecs = () => {
  return JSON.parse(localStorage.getItem("productSpecs")) || [];
}

export const setProductRelateds = (value) => {
  localStorage.setItem("productRelateds", JSON.stringify(value));
};

export const getProductRelateds = () => {
  return JSON.parse(localStorage.getItem("productRelateds")) || [];
}

export const setProductSpecs = (value) => {
  localStorage.setItem("productSpecs", JSON.stringify(value));
};

export const getCartItemsCount = () => {
  return JSON.parse(localStorage.getItem("cartItems")) || [];
}

export const setCartItems = (value) => {
  localStorage.setItem("cartItems", JSON.stringify(value));
};

export const getCartItems = (value) => {
  return JSON.parse(localStorage.getItem("cartItems")) || [];
};

export const getCustomer = () => {
  return JSON.parse(localStorage.getItem("customer")) || [];
}

export const setCustomer = (value) => {
  localStorage.setItem("customer", JSON.stringify(value));
};

export const getProdRating = () => {
  return localStorage.getItem("prodRating");
}

export const setProdRating = (value) => {
  localStorage.setItem("prodRating", value);
}

export const getProdNbRating = () => {
  return localStorage.getItem("prodNbRating");
}

export const setProdNbRating = (value) => {
  localStorage.setItem("prodNbRating", value);
}

export const getAmountToPay = () => {
  return localStorage.getItem("amountToPay");
}

export const setAmountToPay = (value) => {
  localStorage.setItem("amountToPay", value);
}

export const getIdCommande = () => {
  return localStorage.getItem("idCommande");
}

export const setIdCommande = (value) => {
  localStorage.setItem("idCommande", value);
}

export const getBlogData = () => {
  return JSON.parse(localStorage.getItem("blogData")) || [];
};

export const setBlogData = (value) => {
  localStorage.setItem("blogData", JSON.stringify(value));
};

export const getBrandDescription = () => {
  return JSON.parse(localStorage.getItem("brandDescription")) || [];
};

export const setBrandDescription = (value) => {
  localStorage.setItem("brandDescription", JSON.stringify(value));
};
