import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useBrandData = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/brand").then(response => response.json())
    ])
      .then(data => {
        const brandsData = data[0];
        setBrands(brandsData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return { brands };
};

export default useBrandData;