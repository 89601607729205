import { useState, useEffect } from "react";
import { API_URL, getProductData, setProductData, setAllProducts } from "./variables.js";

const useProductData = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/prod").then(response => response.json())
    ])
      .then(data => {
        const productsData = data[0];

        if (localStorage.getItem("productData") !== "undefined") {
          const prodProps = getProductData();
          const foundProduct = productsData.find((product) => product.nom === prodProps.nom);
          setProductData(foundProduct);
        }

        setProducts(productsData);
        setAllProducts(productsData);

      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);


  return { products };
};

export default useProductData;