import React from "react";
import { useParams } from 'react-router-dom';
import { Product } from "./product";
import useProductByTypeData from "../../use-product-by-type-data";

export const TypePage = () => {
  const { category, type } = useParams();

  const { productsByType } = useProductByTypeData(type);

  return (
    <div className="shop">
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6><a href={'/category/'+category} style={{color:'black'}}>{category}</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>{type}</h6>
      </div>
      <div className="shopTitle">
        <h1>{category} - {type}</h1>
      </div>
      <div className="products">
        {productsByType.map((product) => (
          <Product key={product.id} data={product} />
        ))}
      </div>
    </div>
  );
}
