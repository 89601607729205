import React, { useState } from "react";
import { Product } from "./product";
import "./shop.css";
import useProductData from "../../use-product-data";

export const Shop = () => {
  const { products } = useProductData();

  return (
    <div className="shop">
      <div className="row" style={{ marginLeft: '20px', marginTop: '20px' }}>
        <h6><a href='/' style={{ color: 'black' }}>Home</a></h6>
        <h6 style={{ marginLeft: '10px', marginRight: '10px', fontSize: '18px' }}>{'>'}</h6>
        <h6>Shop</h6>
      </div>
      <div className="shopTitle">
        <h1>Tous les produits</h1>
      </div>
      <div className="products">
        {products.map((product) => (
            <Product key={product.id} data={product} />
        ))}
      </div>
    </div>
  );
};
