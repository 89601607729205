import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { API_URL, getCustomer, setIdCommande } from "../variables";

export const ShopContext = createContext(null);

export const ShopContextProvider = (props) => {
  const customer = getCustomer();

  // Count all items in the count
  const getCartItemsCount = () => {
    let count = 0;
    for (const item in cartItems) {
      if (cartItems[item].quantity > 0) {
        count += cartItems[item].quantity;
      }
    }
    return count;
  };

  // Put the cartItems into localStorage variable
  const getCartItemsFromStorage = () => {
    return JSON.parse(localStorage.getItem("cartItems")) || {};
  };

  const setCartItemsToStorage = (cartItems) => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  };

  const [cartItems, setCartItems] = useState(getCartItemsFromStorage());

  // add product into cart
  const addToCart = (itemData, quantity, specSelectedArray, price) => {
    // Create a copy of the current cart items
    const updatedCartItems = { ...cartItems };
    // Check if the item already exists in the cart
    if (updatedCartItems[itemData.id]) {
      // If the item exists, increment its quantity by 1
      updatedCartItems[itemData.id].quantity = quantity;
      updatedCartItems[itemData.id].specSelected = JSON.stringify(specSelectedArray);
      updatedCartItems[itemData.id].typeCommande = "Classic";
      updatedCartItems[itemData.id].price = price
    } else {
      // If the item does not exist, add it to the cart with a quantity of 1
      updatedCartItems[itemData.id] = { ...itemData, quantity: quantity, specSelected: JSON.stringify(specSelectedArray), typeCommande: "Classic", price: price };
    }

    // Update the cart items in the state and the local storage
    setCartItems(updatedCartItems);
    setCartItemsToStorage(updatedCartItems);

    const storedUsername = localStorage.getItem('username');
    if (storedUsername !== null) {

      axios
        .get(API_URL + "api/customer/cart/by-product/" + itemData.id + "/" + customer.id + "/")
        .then(function (response) {
          // handle the response here
          if (response.data.length > 0) {
            const id = response.data[0].id;

            axios
              .put(API_URL + "api/customer/cart/" + id + "/update-quantity/", {
                quantity: quantity,
                price: price,
              })
              .then(response => {
                console.log(response.data);
              })
              .catch(error => {
                console.error(error);
              });
          } else {

            axios
              .post(API_URL + "api/customer/cart/", {
                quantity: quantity,
                produit: "" + API_URL + "api/product/prod/" + itemData.id + "/",
                customer: "" + API_URL + "api/customer/cust/" + customer.id + "/",
                caracteristique: "" + JSON.stringify(specSelectedArray),
                price: price,
              })
              .then(response => {
                console.log(response.data);
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(function (error) {
          // handle errors here
          console.error(error);
        });

    }

  };


  // update cart item
  const updateCartItemCount = (newQuantity, itemId) => {
    // Create a copy of the current cart items
    const updatedCartItems = { ...cartItems };
    // Update the quantity of the specified item in the cart
    if (updatedCartItems[itemId]) {
      // If the item exists, update it from the cart
      updatedCartItems[itemId].quantity = newQuantity;
    }
    
    // Update the cart items in the state and the local storage
    setCartItems(updatedCartItems);
    setCartItemsToStorage(updatedCartItems);

    const storedUsername = localStorage.getItem('username');
      if (storedUsername !== null) {

        axios
        .get(API_URL + "api/customer/cart/by-product/"+itemId+"/"+customer.id+"/")
        .then(function(response) {
          // handle the response here
          if (response.data.length > 0) {
            const id = response.data[0].id;
            let quantity = newQuantity;

            axios
              .put(API_URL + "api/customer/cart/" + id + "/update-quantity/", {
                quantity: quantity
              })
              .then(response => {
                console.log(response.data);
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(function(error) {
          // handle errors here
          console.error(error);
        });
    
      }
  };


  // remove the selected product from the cart
  const removeProductFromCart = (itemData) => {
    // Create a copy of the current cart items
    const updatedCartItems = { ...cartItems };
    // Check if the item exists in the cart
    if (updatedCartItems[itemData.id]) {
      // If the item exists, remove it from the cart
      delete updatedCartItems[itemData.id];
    }
    // Update the cart items in the state and the local storage
    setCartItems(updatedCartItems);
    setCartItemsToStorage(updatedCartItems);

    const storedUsername = localStorage.getItem('username');
    if (storedUsername !== null) {

      axios
        .get(API_URL + "api/customer/cart/by-product/" + itemData.id + "/" + customer.id + "/")
        .then(function (response) {
          // handle the response here
          if (response.data.length > 0) {
            const id = response.data[0].id;

            axios.delete(API_URL + "api/customer/cart/" + id + "/delete-cart/")
              .then(response => {
                // handle success response
                console.log('Cart deleted successfully.');
              })
              .catch(error => {
                // handle error response
                console.error(error);
              });
          }
        })
        .catch(function (error) {
          // handle errors here
          console.error(error);
        });

    }
  };

  const checkout = () => {
    // Convert cartItems object to array
    const cartItemsArray = Object.values(cartItems);
    const date = new Date().toISOString().slice(0, 19).replace('T', ' ');

    axios.post(API_URL + "api/order/order/", {
      date: date,
      customer: customer.id,
      order_products: cartItemsArray.map(item => ({
        type: item.typeCommande,
        quantite: item.quantity,
        produit: item.id,
        caracteristique: item.specSelected,
        price: item.price,
      }))
    }/*, {
      headers: {
        Authorization: `Basic ${btoa('username:password')}` // replace with your actual credentials
      }
    }*/)
      .then(response => {
        setIdCommande(response.data.id)
      })
      .catch(error => {
        alert(`Failed to create Order !`);
      });
  };

  useEffect(() => {
    setCartItemsToStorage(cartItems);
  }, [cartItems]);

  const contextValue = {
    cartItems,
    setCartItems,
    setCartItemsToStorage,
    addToCart,
    getCartItemsCount,
    removeProductFromCart,
    checkout,
    updateCartItemCount,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};
