import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useCustomers = (productId) => {
  const [customerData, setCustomerData] = useState([]);
  const [ratingData, setRatingData] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/customer/cust/").then(response => response.json()),
      fetch(API_URL + "api/product/rating/by-product/"+productId+"/").then(response => response.json())
    ])
      .then(data => {
        const customers = data[0];
        const ratings = data[1];

        setCustomerData(customers);
        setRatingData(ratings);
        
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  });
  
  return { customerData, ratingData };
};

export default useCustomers;