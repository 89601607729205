import React, { useState, useEffect } from "react";
import { getCustomer } from "../../variables";
import useOrders from "../../use-order-data";
import "./suivi-commande.css"
import Separator from "../../components/separator";
import { Button } from 'react-bootstrap';
import html2pdf from 'html2pdf.js';
import { API_URL } from "../../variables";

export const SuiviCommande = () => {
    const customerData = getCustomer();
    const { orderData } = useOrders(customerData.id);

    const dateStr = new Date().toISOString().slice(2, 19).replace('T', '');
    const date = dateStr.slice(0, 8);
    let numFact = dateStr.replace(/-/g, '');
    numFact = numFact.replace(/:/g, '');
    numFact = "#FA" + numFact.slice(0, 10);
    let factTotalAmount = 0;
    let factTotalTax = 0;
    let factTotalLivraison = 0;

    const SENDER_MAIL = process.env.REACT_APP_SENDER_MAIL

    const exportToPDF = () => {
        const element = document.getElementById('histoCommande');

        html2pdf()
            .set({ html2canvas: { scale: 4 } })
            .from(element)
            .save(numFact+'.pdf');
    };

    const [showFacture, setShowFacture] = useState(false);

    useEffect(() => {
        const generateInvoice = async () => {
            const element = document.getElementById('factureCommande');

            setShowFacture(true); // Show the facture before generating the PDF

            await new Promise((resolve) => setTimeout(resolve, 0)); // Wait for the next tick to allow rendering

            html2pdf()
                .set({ html2canvas: { scale: 4 } })
                .from(element)
                .save(numFact+'.pdf')
                .then(() => {
                    setShowFacture(false); // Hide the facture after the PDF is generated
                });
        };

        if (showFacture) {
            generateInvoice();
        }
    }, [showFacture]);

    const handleGenerateInvoice = () => {
        setShowFacture(true);
    };

    const sendEmail = async () => {

        const apiUrl = API_URL + "api/send-email/";
        const emailBody = document.getElementById('histoCommande').innerHTML;

        const formData = new FormData();
        formData.append('sender_email', SENDER_MAIL);
        formData.append('recipient_email', customerData.email);
        formData.append('subject', 'Voici votre historique de commande');
        formData.append('html_content', emailBody);

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('Email sent successfully');
                // Handle success response
            } else {
                console.log('Failed to send email');
                // Handle error response
            }
        } catch (error) {
            console.error('Error sending email', error);
            // Handle error
        }
    }

    return (
        <div className="container-fluid">
            <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
                <h6><a href='/' style={{color:'black'}}>Home</a></h6>
                <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
                <h6>Suivi Commande</h6>
            </div>
            <div className='row' style={{ marginTop: '40px' }}>
                <div className="col-md-3 col-12" style={{ paddingLeft: '40px' }}>
                    <div>
                        {localStorage.getItem('username') + ' (' + localStorage.getItem('emailLogged') + ')'}
                    </div>
                    <h5 style={{ marginTop: '20px' }}>Delivery Address</h5>
                    <h6>Street : {customerData.delivery_street}</h6>
                    <h6>City : {customerData.delivery_city}</h6>
                    <h6>Country : {customerData.delivery_country}</h6>
                    <h6>Postal Code : {customerData.delivery_postal_code}</h6>
                    <h5 style={{ marginTop: '20px' }}>Billing Address</h5>
                    <h6>Street : {customerData.billing_street}</h6>
                    <h6>City : {customerData.billing_city}</h6>
                    <h6>Country : {customerData.billing_country}</h6>
                    <h6>Postal Code : {customerData.billing_postal_code}</h6>
                    <div style={{ zIndex: '10', position: 'fixed', top: '42%', right: '10px', opacity: '80%' }}>
                        <Button variant="success" onClick={exportToPDF}>
                            Export as PDF
                        </Button>
                    </div>
                    <div style={{ zIndex: '10', position: 'fixed', top: '49%', right: '10px', opacity: '80%' }}>
                        <Button variant="success" onClick={handleGenerateInvoice}>
                            Generate an Invoice
                        </Button>
                    </div>
                    <div style={{ zIndex: '10', position: 'fixed', top: '56%', right: '10px', opacity: '80%' }}>
                        <Button variant="info" onClick={sendEmail}>
                            Send by Email
                        </Button>
                    </div>
                </div>
                <div className="col-md-9 col-12" id='histoCommande'>
                    <div style={{ marginBottom: '30px' }}>
                        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>Historique de commande</label>
                    </div>
                    {orderData.map((order) => {
                        let totalAmount = 0; // Reset totalAmount for each order

                        let data = (
                            <div key={'order' + order.num_commande}>
                                <div className="row">
                                    <div style={{ width: '250px' }}><label>Numéro de commande</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.num_commande}</label></div>
                                </div>
                                <div className="row">
                                    <div style={{ width: '250px' }}><label>Date</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.date.replace('T', ' ').slice(0, -1)}</label></div>
                                </div>
                                <div className="row">
                                    <div style={{ width: '250px' }}><label>Status</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.status}</label></div>
                                </div>
                                <div className="row">
                                    <div style={{ width: '250px' }}><label>Produits</label></div>
                                    {order.order_products.length > 0 ? (
                                        <table className="order-products-table">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Quantity</th>
                                                    <th>Type</th>
                                                    <th>Price</th>
                                                    <th>Caracteristique</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.order_products.map((product) => {
                                                    totalAmount += product.price * product.quantite;
                                                    let tableData = (
                                                        <tr key={'productTR' + product.nom}>
                                                            <td>{product.nom}</td>
                                                            <td>{product.quantite}</td>
                                                            <td>{product.type}</td>
                                                            <td>${product.price}</td>
                                                            <td>{product.caracteristique && JSON.parse(product.caracteristique).map((item) => (
                                                                <div key={item.code}>
                                                                    {item.code}: {item.valeur}
                                                                </div>
                                                            ))}</td>
                                                        </tr>
                                                    )
                                                    return tableData;
                                                })}
                                            </tbody>
                                        </table>

                                    ) : (
                                        <p>No products found for this order.</p>
                                    )}
                                </div>
                                <div className="row" style={{ marginTop: '15px', marginBottom: '20px' }}>
                                    <div style={{ width: '250px' }}><label>Total Amount</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>${totalAmount}</label></div>
                                </div>
                                {(order.livraison !== null) ? (<div className="row">
                                    <div style={{ width: '250px' }}><label>Mode de livraion</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.livraison.nom}</label></div>
                                </div>) : null
                                }
                                {(order.livraison !== null) ? (<div className="row">
                                    <div style={{ width: '250px' }}><label>Formule</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.livraison.formule}</label></div>
                                </div>) : null
                                }
                                {(order.livraison !== null) ? (<div className="row">
                                    <div style={{ width: '250px' }}><label>Transporteur</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.livraison.transporteur}</label></div>
                                </div>) : null
                                }
                                {(order.livraison !== null) ? (<div className="row">
                                    <div style={{ width: '250px' }}><label>Delai de livraison</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.livraison.delai_livraison}</label></div>
                                </div>) : null
                                }
                                {(order.livraison !== null) ? (<div className="row">
                                    <div style={{ width: '250px' }}><label>Prix de livraison ($)</label></div>
                                    <div><label style={{ marginLeft: '20px' }}>{order.livraison.prix_livraison}</label></div>
                                </div>) : null
                                }
                                <Separator />
                            </div>
                        )
                        return data;
                    }
                    )}


                </div>
            </div>


            {showFacture && (
                <div className="col-12" id='factureCommande'>
                    <div className='row' style={{ marginTop: '20px' }}>
                        <div className="justify-content-center text-center" style={{ width: '70%', marginTop: '-10px' }}>
                            <span style={{ fontSize: '40px', fontWeight: 'bolder' }}>LOGO</span>
                        </div>
                        <div style={{ width: '30%', textAlign: 'center' }}>
                            <h5>Facture</h5>
                            <h6>{date}</h6>
                            <h6>{numFact}</h6>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '20px' }}>
                        <div style={{ width: '50%', paddingLeft: '40px' }}>
                            <div><h5>Adresse de facturation</h5></div>
                            <div>{customerData.billing_street}</div>
                            <div>{customerData.billing_city}</div>
                            <div>{customerData.billing_country}</div>
                            <div>{customerData.billing_postal_code}</div>
                        </div>
                        <div style={{ width: '50%' }}>
                            <div><h5>Adresse de livraison</h5></div>
                            <div>{customerData.delivery_street}</div>
                            <div>{customerData.delivery_city}</div>
                            <div>{customerData.delivery_country}</div>
                            <div>{customerData.delivery_postal_code}</div>
                        </div>
                    </div>

                    {orderData.map((order) => {
                        if (order.livraison !== null){
                            factTotalLivraison += parseFloat(order.livraison.prix_livraison);
                        }
                        let data = (
                            <div key={'factOrder' + order.num_commande}>
                                <table className="order-products-table" style={{ marginTop: '50px' }}>
                                    <thead>
                                        <tr>
                                            <th>Numero de facture</th>
                                            <th>Date de facturation</th>
                                            <th>Num Commande</th>
                                            <th>Date de commande</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{numFact}</td>
                                            <td>20{date}</td>
                                            <td>{order.num_commande}</td>
                                            <td>{order.date.slice(0, 10)}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div style={{ marginTop: '20px' }}>
                                    {order.order_products.length > 0 ? (
                                        <table className="order-products-table">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Taux de taxe</th>
                                                    <th>PU (HT)</th>
                                                    <th>Quantité</th>
                                                    <th>Total (HT)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.order_products.map((product) => {
                                                    const prixHt = (product.price / (1 + (product.tva / 100)));
                                                    factTotalAmount += (prixHt * product.quantite);
                                                    factTotalTax += product.price - prixHt;
                                                    let tableData = (
                                                        <tr key={'productTR' + product.nom}>
                                                            <td>{product.nom}</td>
                                                            <td>{product.tva}%</td>
                                                            <td>${prixHt.toFixed(2)}</td>
                                                            <td>{product.quantite}</td>
                                                            <td>${(prixHt * product.quantite).toFixed(2)}</td>
                                                        </tr>
                                                    )
                                                    return tableData;
                                                })}
                                            </tbody>
                                        </table>
                                    ) : (
                                    <p>No products found for this order.</p>
                                    )}
                                </div>

                                {(order.livraison !== null) ?
                                        (<table style={{marginTop:'10px'}}>
                                            <tr>
                                                <th><span>Transporteur</span></th>
                                                <td><span>{order.livraison.transporteur}</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>Délai de livraison</span></th>
                                                <td><span>{order.livraison.delai_livraison}</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>Prix de livraison ($)</span></th>
                                                <td><span>{order.livraison.prix_livraison}</span></td>
                                            </tr>
                                        </table>) : null
                                    }
                            </div>
                        )
                        return data;
                    }
                    )}


                    <div className="row" style={{ marginTop: '50px' }}>
                        <div style={{ width: '50%', paddingLeft: '20px' }}>
                            <table>
                                <tr>
                                    <th><span>Commentaire</span></th>
                                    <td><span>-----------</span></td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ width: '50%', paddingLeft: '20px', paddingRight: '20px' }}>
                            <table>
                                <tr>
                                    <th><span>Total (HT)</span></th>
                                    <td><span style={{ fontWeight: 'bolder' }}>$ {factTotalAmount.toFixed(2)}</span></td>
                                </tr>
                                <tr>
                                    <th><span>Taxe Total</span></th>
                                    <td><span style={{ fontWeight: 'bolder' }}>$ {factTotalTax.toFixed(2)}</span></td>
                                </tr>
                                <tr>
                                    <th><span>Frais Livraison Total</span></th>
                                    <td><span style={{ fontWeight: 'bolder' }}>$ {factTotalLivraison.toFixed(2)}</span></td>
                                </tr>
                                <tr>
                                    <th><span>Total</span></th>
                                    <td><span style={{ fontWeight: 'bolder' }}>$ {(factTotalAmount + factTotalTax + factTotalLivraison).toFixed(2)}</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
