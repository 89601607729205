import React, { useState } from "react";
import { API_URL, getProductExtraPhotos, getProductData, getCustomer, setProductExtraPhotoData } from '../../../variables';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Photos() {
    const prodExtraImages = getProductExtraPhotos();
    const prodData = getProductData();
    const customerData = getCustomer();
    const navigate = useNavigate();

    const [showModalAddPhoto, setShowModalAddPhoto] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleClick = (extraImage) => {
        setProductExtraPhotoData(extraImage);
        navigate("/community");
    };

    const handleAddClick = () => {
        setShowModalAddPhoto(true);
    };

    const handleModalAddPhoto = () => {
        setShowModalAddPhoto(!showModalAddPhoto);
    }

    // Register review
    const handleModalAddPhotoValidate = async () => {
        const description = document.getElementById('descriptionPhotoExtra').value;

        // Handle validation logic
        if (selectedFile) {
            // Perform operations with the selected file
            console.log('Selected file:', selectedFile);
        } else {
            console.log('No file selected');
        }

        const formData = new FormData();
        formData.append('description', description); // Add the description field
        formData.append('image', selectedFile); // Add the file from your computer
        formData.append('produit', prodData.url);
        formData.append('customer', customerData.url);

    // Make a POST request to the API endpoint
    fetch(API_URL + "api/product/photo-extra/", {
    method: 'POST',
    body: formData,
    })
    .then(response => response.json())
    .then(data => {
        // Handle the response
        console.log(data);
        setShowModalAddPhoto(false);
    })
    .catch(error => {
        // Handle the error
        console.error(error);
    });
    }

    return (
        <div >
            <div style={{ margin: '20px' }}>
                <Button variant="outline-success" onClick={handleAddClick}>
                    ADD A PHOTO
                </Button>
            </div>

            <div className="products">
                {
                    (prodExtraImages.length > 0) ?
                        (
                            <>
                                {prodExtraImages.map((extraImage, index) => (
                                    <div className="product grow" data={extraImage} onClick={() => handleClick(extraImage)} key={"prodExtraImages" + extraImage.produit} >
                                        <div>
                                            <img src={extraImage.image !== '' ? API_URL + extraImage.image : null} alt="" />
                                        </div>
                                        <h5 style={{ marginTop: '10px' }}>{extraImage.customer_username}</h5>
                                        <h5>{extraImage.description}</h5>
                                    </div>
                                )
                                )}
                            </>
                        ) :
                        null
                }
            </div>

            {/* Add a Photo modal */}
            <Modal show={showModalAddPhoto} onHide={handleModalAddPhoto}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        ADD A PHOTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="align-items-center justify-content-center text-center">
                        <div style={{ marginTop: '25px' }}>
                            <label>Description </label>
                        </div>
                        <div>
                            <input type={'text'} id="descriptionPhotoExtra"></input>
                        </div>
                        <div style={{ marginTop: '25px' }}>
                            <label>Photo </label>
                        </div>
                        <div>
                            <input type="file" id="photo" onChange={handleFileInputChange} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalAddPhoto}>
                        Leave
                    </Button>
                    <Button variant="primary" onClick={handleModalAddPhotoValidate}>
                        Validate
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Photos;