import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const usePhotoData = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/photo").then(response => response.json())
    ])
      .then(data => {
        const photosData = data[0];
        setPhotos(photosData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return { photos };
};

export default usePhotoData;