import React from 'react';
import { FaPaypal } from 'react-icons/fa';
import { FaStripe } from 'react-icons/fa';
import useTrustTextsData from '../use-trust-texts-data';
import useTypeData from '../use-types-data';
import { Link, useNavigate } from "react-router-dom";
import './footer.css'
import { FaFacebook } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaGooglePlus } from 'react-icons/fa';
import { FaTelegramPlane } from 'react-icons/fa';

export const Footer = () => {
  const { trustTexts } = useTrustTextsData();

  const isMobile = window.innerWidth <= 768;

  const { types } = useTypeData();
  let categoryDataTemp = '';
  let currentLine = [];

  const navigate = useNavigate();

  return (


    <footer className="footer-section">
      <div className="container">
        {!isMobile && (<div className="footer-cta pt-5 pb-5">
          <div className="row">
            {trustTexts.map((trustText, index) => (
              <div  key={'trustTText'+index} style={{marginRight:'40px'}}>
                <div className="single-cta">
                  <div className="cta-text">
                    <h4>{trustText.title}</h4>
                  <span>{trustText.description}</span>
                </div>
              </div>
            </div>
            ))}
          </div>
        </div>)}
        
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <h1 id='footer-logo'>LOGO</h1>
                </div>
                {!isMobile && (<div className="footer-text">
                  <p>Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed do eiusmod tempor incididuntut consec tetur adipisicing
                    elit,Lorem ipsum dolor sit amet.</p>
                </div>)}
                {!isMobile && (<div className="footer-social-icon">
                  <span>Follow us</span>
                  <a href="#"><FaFacebook /></a>
                  <a href="#"><FaTwitter /></a>
                  <a href="#"><FaGooglePlus /></a>
                </div>)}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/shop">Shop</a></li>
                  {types.map((type, index) => (
                    <li><a href={`/${type.category.nom}/${type.nom}`} >{type.nom}</a></li>
                  ))}
                  <li><a href="#">About us</a></li>
                  <li><a href="#">Contact us</a></li>
                  <li><a href="/blog">Blog</a></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                {!isMobile && (<div className="footer-text mb-25">
                  <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                </div>)}
                <div className="subscribe-form">
                  <form action="#">
                    <input type="text" placeholder="Email Address" />
                    <button><FaTelegramPlane /></button>
                  </form>
                </div>
                {!isMobile && (<div id='payment-types'>
                  <span>Payment Types</span>
                  <ul style={{marginTop:'10px'}}>
                    <li style={{listStyle:'none'}}><FaPaypal size={25} style={{marginRight:'4px'}}/>Paypal</li>
                    <li style={{listStyle:'none'}}><FaStripe size={35} style={{marginRight:'4px'}} />Stripe</li>
                  </ul>
                </div>)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (<div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="text-lg-left">
              <div className="copyright-text">
                <p>Copyright &copy; 2023, All Right Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </footer>
  );
};