import React, { useState } from "react";
import { Product } from "./product";
import "./shop.css";
import useProductData from "../../use-product-data";
import Banner from "../../components/banner";
import useCategoryData from "../../use-category-data";
import useBrandData from "../../use-brands-data";
import useBlogs from "../../use-blogs-data";
import { setBlogData, setBrandDescription } from "../../variables";
import { useNavigate } from "react-router-dom";
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import config from "../../config";

export const Home = () => {
  const { products } = useProductData();
  const { categories } = useCategoryData();
  const { brands } = useBrandData();
  const { blogData } = useBlogs();
  const isMobile = window.innerWidth <= 768;

  const bestSellersTitle = config.bestSellersTitle;
  const nouveautesTitle = config.nouveautesTitle;
  const marquesTitle = config.marquesTitle;
  const blogsTitle = config.blogsTitle;

  const [activeAccordion, setActiveAccordion] = useState(-1);

  const toggleAccordion = (index) => {
    setActiveAccordion(index === activeAccordion ? -1 : index);
  };

  const navigate = useNavigate();

  return (
    <div>
      {!isMobile && (<Banner />)}

      {isMobile && (<div style={{height:'60px'}}/>)}

      <div className="products">
        {
          (categories.length > 0) ?
            (
              <>
                {categories.map((category, index) => (
                  <div className="home-element grow" onClick={() => { navigate(`/category/${category.nom}`) }} key={"category" + category.nom}>
                    <div id='home-element-div'>
                      <img src={category.image !== '' ? category.image : null} alt="" id='home-category-img' />
                      <h4 className="home-element-content">{category.nom}</h4>
                    </div>
                  </div>
                )
                )}
              </>
            ) :
            null
        }
      </div>

      {!isMobile && (<div>
        <div className="shopTitle">
          <h3>{bestSellersTitle}</h3>
        </div>
        <div className="products">
          {products.map((product) => (
            product.best_seller === true ?
              <Product key={product.id} data={product} />
              : null
          ))}
        </div>
      </div>)}

      {isMobile && (
      <div>
        <div className="shopTitle" onClick={() => toggleAccordion(0)}>
          <div className="container">
            <h3 id='sous-titre'>{bestSellersTitle}</h3>
            <div id='accordion-arrow'>{activeAccordion === 0 ? <FaArrowUp /> : <FaArrowDown />}</div>
          </div>
        </div>
        <div className={`${activeAccordion === 0 ? "products" : "hide"}`}>
          {products.map((product) => (
            product.best_seller === true ?
              <Product key={product.id} data={product} />
              : null
          ))}
        </div>
      </div>)}

      
      {!isMobile && (<div>
        <div className="shopTitle">
          <h3>{nouveautesTitle}</h3>
        </div>
        <div className="products">
          {products.map((product) => (
            product.nouveaute === true ?
              <Product key={product.id} data={product} />
              : null
          ))}
        </div>
      </div>)}

      {isMobile && (
      <div>
        <div className="shopTitle" onClick={() => toggleAccordion(1)}>
          <div className="container">
            <h3 id='sous-titre'>{nouveautesTitle}</h3>
            <div id='accordion-arrow'>{activeAccordion === 1 ? <FaArrowUp /> : <FaArrowDown />}</div>
          </div>
        </div>
        <div className={`${activeAccordion === 1 ? "products" : "hide"}`}>
          {products.map((product) => (
              product.nouveaute === true ?
                <Product key={product.id} data={product} />
                : null
          ))}
        </div>
      </div>)}


      {!isMobile && (<div>
        <div className="shopTitle">
          <h3>{marquesTitle}</h3>
        </div>
        <div className="products">
          {
            (brands.length > 0) ?
              (
                <>
                  {brands.map((brand, index) => (
                    <div
                      className="home-element grow"
                      onClick={() => {
                        setBrandDescription(brand.description);
                        navigate(`/brand/${brand.nom}`)
                      }}
                      key={"brand" + brand.nom}>
                      <div id='home-element-div'>
                        <img src={brand.image !== '' ? brand.image : null} alt="" id='home-element-img' />
                        <h4 className="home-element-content">{brand.nom}</h4>
                      </div>
                    </div>
                  )
                  )}
                </>
              ) :
              null
          }
        </div>
      </div>)}

      {isMobile && (
      <div>
        <div className="shopTitle" onClick={() => toggleAccordion(2)}>
          <div className="container">
            <h3 id='sous-titre'>{marquesTitle}</h3>
            <div id='accordion-arrow'>{activeAccordion === 2 ? <FaArrowUp /> : <FaArrowDown />}</div>
          </div>
        </div>
        <div className={`${activeAccordion === 2 ? "products" : "hide"}`}>
        {
            (brands.length > 0) ?
              (
                <>
                  {brands.map((brand, index) => (
                    <div
                      className="home-element grow"
                      onClick={() => {
                        setBrandDescription(brand.description);
                        navigate(`/brand/${brand.nom}`)
                      }}
                      key={"brand" + brand.nom}>
                      <div id='home-element-div'>
                        <img src={brand.image !== '' ? brand.image : null} alt="" id='home-element-img' />
                        <h4 className="home-element-content">{brand.nom}</h4>
                      </div>
                    </div>
                  )
                  )}
                </>
              ) :
              null
          }
        </div>
      </div>)}


      {!isMobile && (<div>
        <div className="shopTitle">
          <h3>{blogsTitle}</h3>
        </div>
        <div className="products">
          {
            (brands.length > 0) ?
              (
                <>
                  {blogData.map((blog, index) => {
                    if (blog.type === "Blog") {
                      return (
                        <div
                          className="home-element grow"
                          onClick={() => { navigate(`/blog/${blog.titre}/`); setBlogData(blog) }}
                          key={"blog" + blog.titre}>
                          <div id='home-element-div'>
                            <img src={blog.cover_image !== '' ? blog.cover_image : null} alt="" id='home-element-img' />
                            <h4 className="home-element-content">{blog.titre}</h4>
                          </div>
                        </div>
                      );
                    }
                    else {
                      return ""
                    }
                  }
                  )}
                </>
              ) :
              null
          }
        </div>
      </div>)}

      {isMobile && (
      <div>
        <div className="shopTitle" onClick={() => toggleAccordion(3)}>
          <div className="container">
            <h3 id='sous-titre'>{blogsTitle}</h3>
            <div id='accordion-arrow'>{activeAccordion === 3 ? <FaArrowUp /> : <FaArrowDown />}</div>
          </div>
        </div>
        <div className={`${activeAccordion === 3 ? "products" : "hide"}`}>
        {
            (brands.length > 0) ?
              (
                <>
                  {blogData.map((blog, index) => {
                    if (blog.type === "Blog") {
                      return (
                        <div
                          className="home-element grow"
                          onClick={() => { navigate(`/blog/${blog.titre}/`); setBlogData(blog) }}
                          key={"blog" + blog.titre}>
                          <div id='home-element-div'>
                            <img src={blog.cover_image !== '' ? blog.cover_image : null} alt="" id='home-element-img' />
                            <h4 className="home-element-content">{blog.titre}</h4>
                          </div>
                        </div>
                      );
                    }
                    else {
                      return ""
                    }
                  }
                  )}
                </>
              ) :
              null
          }
        </div>
      </div>)}

    </div>
  );
};
