import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useBlogs = () => {
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/blog/").then(response => response.json())
    ])
      .then(data => {
        const blogs = data[0];
        setBlogData(blogs);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  
  return { blogData };
};

export default useBlogs;