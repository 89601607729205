import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useTrustTextsData = () => {
  const [trustTexts, setTrustTexts] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/setting/trust-text").then(response => response.json())
    ])
      .then(data => {
        const trustTextsData = data[0];
        setTrustTexts(trustTextsData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return { trustTexts };
};

export default useTrustTextsData;