import React from "react";
import { useParams } from 'react-router-dom';
import { Product } from "./product";
import useProductByCategoryData from "../../use-product-by-category-data";

export const CategoryPage = () => {
  const { category } = useParams();
  const { productsByCategory } = useProductByCategoryData(category);

  return (
    <div className="shop">
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>{category}</h6>
      </div>
      <div className="shopTitle">
        <h1>{category}</h1>
      </div>
      <div className="products">
        {productsByCategory.map((product) => (
          <Product key={product.id} data={product} />
        ))}
        </div>
    </div>
  );
}
