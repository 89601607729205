import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useTypeData = () => {
  const [types, setTypes] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/type").then(response => response.json())
    ])
      .then(data => {
        const typesData = data[0];
        setTypes(typesData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return { types };
};

export default useTypeData;