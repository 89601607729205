import React from "react";
import useBlogs from "../../use-blogs-data";
import "./style.css"
import { useNavigate } from "react-router-dom";
import { setBlogData } from "../../variables";

export const Blog = () => {
  const { blogData } = useBlogs();
  const navigate = useNavigate();

  return (
    <div style={{ width: '100%' }}>
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>Blog</h6>
      </div>
      <div className="d-flex align-items-center justify-content-center text-center links" style={{marginTop:'40px'}}>
        <h1>ALL BLOG TOPICS</h1>
      </div>
      <div className="blogs" style={{ marginTop: '40px' }}>
        {blogData.map((blog, index) => {
          if (blog.type === "Blog") {
            return (
              <div className="blog-element grow" onClick={() => { navigate(`/blog/${blog.titre}/`); setBlogData(blog) }} key={"blog" + blog.titre}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                  <img src={blog.cover_image !== '' ? blog.cover_image : null} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                  <div className="blog-element-content">
                    <h4>{blog.titre}</h4>
                    <h6>{blog.auteur}</h6>
                  </div>
                </div>
              </div>
            );
          }
          else {
            return "";
          }
        }
        )}
      </div>
    </div>
  );
}