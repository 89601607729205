import React, { useState } from "react";

function StyledTextarea() {
  const [text, setText] = useState("");

  const handleStyleClick = (style) => {
    document.execCommand(style, false, null);
  };

  const handleFontSizeChange = (e) => {
    document.execCommand("fontSize", false, e.target.value);
  };

  const handleFontFamilyChange = (e) => {
    document.execCommand("fontName", false, e.target.value);
  };

  const handleTextColorChange = (e) => {
    document.execCommand("foreColor", false, e.target.value);
  };

  const handleBackgroundColorChange = (e) => {
    document.execCommand("hiliteColor", false, e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.innerHTML);
  };

  return (
    <>
        <div
          contentEditable
          id="avisTextarea"
          onInput={handleTextChange}
          style={{ width: "90%", height: "300px", border: "1px solid black", marginLeft: "25px", textAlign: "left" }}
        />
        <div>
            <button onClick={() => handleStyleClick("bold")}>Bold</button>
            <button onClick={() => handleStyleClick("italic")}>Italic</button>
            <button onClick={() => handleStyleClick("underline")}>Underline</button>
            <button onClick={() => handleStyleClick("strikeThrough")}>Strikethrough</button>
            <select onChange={handleFontSizeChange}>
                <option value="">Font Size</option>
                <option value="1">Small</option>
                <option value="3">Medium</option>
                <option value="5">Large</option>
            </select>
            <select onChange={handleFontFamilyChange}>
                <option value="">Font Family</option>
                <option value="Arial">Arial</option>
                <option value="Helvetica">Helvetica</option>
                <option value="Times New Roman">Times New Roman</option>
            </select>
            <input type="color" onChange={handleTextColorChange} />
            <input type="color" onChange={handleBackgroundColorChange} />
        </div>
    </>
  );
}

export default StyledTextarea;
