import React, { useState } from "react";
import { API_URL, getProductExtraPhotoData, getCustomer } from "../../variables";
import SeparatorV from "../../components/separator-v";
import StyledTextarea from "../../components/styled-textarea";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import useCustomers from "../../use-customers-data";
import useComments from "../../use-comment-data";
import ReactHtmlParser from 'html-react-parser';

export const Community = () => {
  const productExtraPhotoData = getProductExtraPhotoData();
  const [showModaAvis, setShowModalAvis] = useState(false);
  const currentCustomer = getCustomer();

  const { customerData } = useCustomers(0);
  const { commentData } = useComments(productExtraPhotoData.id);

  const handleModalAvis = () => {
    setShowModalAvis(!showModaAvis);
  }

  // Register review
  const handleModalAvisValidate = async (value) => {
    const avis = document.getElementById('avisTextarea').innerHTML;
    axios
      .post(API_URL + "api/product/comment/", {
        avis: avis,
        photo_extra: "" + API_URL + "api/product/photo-extra/" + productExtraPhotoData.id + "/",
        customer: "" + API_URL + "api/customer/cust/" + currentCustomer.id + "/"
      },
        {
          'Content-Type': 'application/json'
        }
      )
      .then(response => {
        setShowModalAvis(false);
        alert('Comment added succesfully !')
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <div style={{ paddingTop: '40px', width: '100%' }}>
      <div className="container align-items-center justify-content-center text-center">
        <h4>{productExtraPhotoData.description}</h4>
        <SeparatorV />
        <h6>Auteur : {productExtraPhotoData.customer_username}</h6>
        <div>
          <img src={productExtraPhotoData.image !== '' ? API_URL + productExtraPhotoData.image : null} alt="" />
        </div>
        <div style={{ marginTop: '40px', width: '100%' }}>
          <Button variant="outline-success" onClick={() => { setShowModalAvis(true) }} >
            ADD A COMMENT
          </Button>
        </div>

        {/* Comments div */}
        <div className='col d-flex' style={{ marginTop: '40px', paddingLeft: '12%' }}>
          <div>
            <div><p style={{ fontWeight: 'bolder', fontSize: '200%' }}>COMMENTS</p></div>
            <div>
              {commentData.map((data, index) => {
                let customer = customerData.find((cust) => cust.url === data.customer);

                if (customer !== undefined) {
                  console.log("customer = ", customer)
                  console.log("prenom = ", customer.prenom)
                  return (
                    <div style={{ marginBottom: '40px' }} key={'commentDiv' + index}>
                      <span key={'commentUserneme' + index} style={{ fontWeight: "bold", fontStyle: 'italic' }}>{customer.user.username}</span><br></br>
                      <span key={'avis' + index}>{ReactHtmlParser(data.avis)}</span>
                    </div>)
                }
                else { return "" };
              })}
            </div>
          </div>
        </div>
      </div>

      {/* GIVE YOUR OPINION modal */}
      <Modal show={showModaAvis} onHide={handleModalAvis}>
        <Modal.Header closeButton>
          <Modal.Title>
            GIVE YOUR COMMENT
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            <div>
              <StyledTextarea />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalAvis}>
            Leave
          </Button>
          <Button variant="primary" onClick={handleModalAvisValidate}>
            Validate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}