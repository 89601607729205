import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { getAmountToPay } from "../../variables";
import Stripe from "stripe";
import axios from "axios";
import { API_URL, getIdCommande } from "../../variables";
import { Buffer } from 'buffer';
import { ShopContext } from "../../context/shop-context";
import "./checkout.css"

const sandbox = "https://api-m.sandbox.paypal.com";
// const production = "https://api-m.paypal.com";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const APP_SECRET = process.env.REACT_APP_APP_SECRET

console.log('client id = ',CLIENT_ID)

// Sets up the transaction when a payment button is clicked
export async function createOrder() {
    const purchaseAmount = getAmountToPay().toString(); // TODO: pull prices from a datasandbox
    const accessToken = await generateAccessToken();
    const url = `${sandbox}/v2/checkout/orders`;
    const response = await fetch(url, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            intent: "CAPTURE",
            purchase_units: [
                {
                    amount: {
                        currency_code: "USD",
                        value: purchaseAmount,
                    },
                },
            ],
        }),
    });

    return handleResponse(response);
};

// capture payment for an order
async function capturePayment(orderId) {
    const accessToken = await generateAccessToken();
    const url = `${sandbox}/v2/checkout/orders/${orderId}/capture`;
    const response = await fetch(url, {
        method: "post",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    });

    return handleResponse(response);
}

// generate access token
export async function generateAccessToken() {
    const auth = Buffer.from(CLIENT_ID + ":" + APP_SECRET).toString("base64");
    const response = await fetch(`${sandbox}/v1/oauth2/token`, {
        method: "post",
        body: "grant_type=client_credentials",
        headers: {
            Authorization: `Basic ${auth}`,
        },
    });
    const jsonData = await handleResponse(response);
    return jsonData.access_token;
}

async function handleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response.json();
    }

    const errorMessage = await response.text();
    throw new Error(errorMessage);
}

export const Payment = () => {
    const location = useLocation();
    const { setCartItems } = useContext(ShopContext);

    const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY
    const stripe = new Stripe(STRIPE_API_KEY);

    const amountToPay = getAmountToPay();

    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCVV] = useState('');

    const [paypalLoaded, setPaypalLoaded] = useState(false);
    const [paypalError, setPaypalError] = useState(null);

    useEffect(() => {
        console.log("location.state.fromContactButton = ", location.state.fromContactButton)
        if (!location.state || !location.state.fromContactButton) {
            // Redirect to home page if the user didn't click the checkout button
            window.location.href = "/";
        }
    }, [location.state]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=test&currency=USD';
        script.addEventListener('load', () => {
            setPaypalLoaded(true);
        });
        script.addEventListener('error', (event) => {
            setPaypalError(event);
        });
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        if (paypalLoaded && window.paypal && document.getElementById('paypal-button-container')) {
            window.paypal.Buttons({
                createOrder: async function () {
                    const orderId = await createOrder();
                    return orderId.id;
                },
                onApprove: async function (data) {
                    const captureResponse = await capturePayment(data.orderID);
                    // Do something with the capture response
                    console.log(captureResponse);
                    const transaction = captureResponse.purchase_units[0].payments.captures[0];
                    setCartItems({}); // Vider Cart
                    window.location.href = "/";
                    alert(
                    "Transaction " +
                        transaction.status +
                        ": " +
                        transaction.id
                    );
                },
            }).render('#paypal-button-container');
        }
    }, [paypalLoaded]);

    if (paypalError) {
        return <div>PayPal SDK failed to load.</div>;
    }

    const handleCancel = () => {
        const idCommande = getIdCommande();
        axios
                  .put(API_URL + "api/order/order/" + idCommande + "/", {
                    status: "Annulée"
                  })
                  .then(response => {
                    console.log("Order Candeled");
                  })
                  .catch(error => {
                    console.error(error);
                  });
      }

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();

        let error = null;
        let paymentMethod = null;

        const result = await stripe.paymentMethods.create({
            type: "card",
            card: {
                number: cardNumber.replace(/-/g, ""),
                exp_month: parseInt(expiryDate.split("/")[0]),
                exp_year: parseInt("20" + expiryDate.split("/")[1]),
                cvc: cvv,
            },
        });

        error = result.error;
        paymentMethod = result.id;

        if (error) {
            alert("Payment method error:", error);
            return;
        } else {
            const { clientSecret } = await axios.post(API_URL + "api/create-payment-intent", {
                amount: amountToPay * 100, // NEED * 100 FOR STRIPE
                card_id: paymentMethod
            });

            if (clientSecret !== '') {
                setCartItems({}); // Vider Cart
                alert("Payment successful!");
                window.location.href = "/";
            }
        }
    };



    function handleCardNumberChange(event) {
        const input = event.target.value.replace(/\D/g, '').substring(0, 16);
        const cardNumberFormatted = input.replace(/(.{4})/g, '$1-');
        setCardNumber(cardNumberFormatted.substring(0, 19));
    }

    function handleExpiryDateChange(event) {
        const input = event.target.value.replace(/\D/g, '').substring(0, 4);
        const expiryDateFormatted = input.replace(/(.{2})/g, '$1/');

        setExpiryDate(expiryDateFormatted.substring(0, 5));
    }

    function handleCVVChange(event) {
        const input = event.target.value.substring(0, 3);
        setCVV(input.substring(0, 3));
    }


    return (
        <div style={{ paddingLeft: '40px', paddingTop: '80px', width: '100%' }} id="paymentDiv">
            <form onSubmit={handlePaymentSubmit}>
                <div className="container-fluid" style={{ marginBottom: '40px', marginLeft: '-20px' }} id="paymentDivContainer">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: '40px', width: '80%', marginLeft: '100px', height: '350px' }} id="stripePaymentDiv">
                                <h2>Stripe Payment</h2>
                                <div style={{ marginTop: '20px' }}>
                                    <label style={{ width: '150px' }} htmlFor="cardNumber">Card Number:</label>
                                    <input
                                        type="text"
                                        id="cardNumber"
                                        style={{ width: '300px' }}
                                        maxLength="19"
                                        placeholder="XXXX-XXXX-XXXX-XXXX"
                                        value={cardNumber}
                                        onChange={handleCardNumberChange}
                                    />
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <label style={{ width: '150px' }} htmlFor="expiryDate">Expiry Date:</label>
                                    <input
                                        type="text"
                                        id="expiryDate"
                                        style={{ width: '100px' }}
                                        maxLength="5"
                                        placeholder="M/YY"
                                        value={expiryDate}
                                        onChange={handleExpiryDateChange}
                                    />
                                </div>

                                <div style={{ marginTop: '15px' }}>
                                    <label style={{ width: '150px' }} htmlFor="cvv">CVV:</label>
                                    <input
                                        type="number"
                                        id="cvv"
                                        style={{ width: '100px' }}
                                        maxLength="3"
                                        placeholder="000"
                                        value={cvv}
                                        onChange={handleCVVChange}
                                    />
                                </div>

                                <button type="submit" className="btn btn-primary" style={{ marginTop: '30px' }}>
                                    Submit Payment
                                </button>
                            </div>
                        </div>

                        <div className="col-md-5 col-11" style={{ border: "1px solid #ccc", borderRadius: "5px", padding: '40px' }} id="paypalPayment">

                            <h2>PayPal Payment</h2>
                            <p>Please click the Submit Payment button below to continue to PayPal</p>

                            <div id="paypal-button-container"></div>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>
                </div>
            </form>
            <button 
            type="submit" 
            id="cancelButton"
            className="btn btn-danger" 
            style={{ marginTop: '40px', marginLeft:'100px' }}
            onClick={()=>{
                handleCancel();
                window.location.href = "/";
            }}
            >
            Annuler
          </button>
        </div>
    );
};
