import React from "react";
import { useNavigate } from 'react-router-dom';
import { API_URL, setProductPhotos, setProductData, setProductSpecs, setProductRelateds, setProductExtraPhotos } from "../../variables";
import ReactStars from 'react-rating-stars-component';

export const Product = (props) => {
  const { nom, original_price, actual_price, prod_images, prod_specs, prod_relateds, prod_extra_images, nb_rating } = props.data;

  const prodImages = JSON.parse(prod_images);

  const prodSpecs = JSON.parse(prod_specs);

  const prodRelateds = JSON.parse(prod_relateds);

  const prodExtraImages = JSON.parse(prod_extra_images);

  const navigate = useNavigate();

  const handleClick = () => {
    // set all products data with details
    setProductPhotos(prodImages);
    setProductSpecs(prodSpecs);
    setProductData(props.data);
    setProductRelateds(prodRelateds);
    setProductExtraPhotos(prodExtraImages);

    // go to product detail page
    navigate("/product-details")
  };

  return (
    <div id='productContainer'>
      <div className="product grow" onClick={handleClick}>
        <div>
          <img src={prodImages.length > 0 ? API_URL + prodImages[0].image : null} alt="" />
        </div>
      </div>
      <div className="description">
        <div>
          <b>{nom}</b>
        </div>
        <div>
        <ReactStars
          activeColor="#ffd700"
          count={5}
          size={23}
          isHalf={true}
          value={ Number(nb_rating) }
        />
        </div>
        <div>
          {
            (original_price === actual_price) ?
              '$' + original_price :
              (
                <>
                  <label id='product-original-price-label'>${original_price}</label>
                  <label id='product-actual-price-label'>${actual_price}</label>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};
