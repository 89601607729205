import React, { useContext, useState } from "react";
import { ShopContext } from "../../context/shop-context";
import { API_URL } from "../../variables";
import { Button } from "react-bootstrap";

export const CartItem = (props) => {
  const { id, nom, original_price, actual_price, prod_images, is_vente_groupee } = props.data;
  const { cartItems, removeProductFromCart, setCartItems, setCartItemsToStorage, updateCartItemCount } = useContext(ShopContext);

  const [commandType, setCommandType] = useState('Classic');

  const handleCommandTypeChange = (event) => {
    const selectedCommandType = event.target.value;
    setCommandType(selectedCommandType);

    const updatedCartItems = { ...cartItems };
    updatedCartItems[id].typeCommande = event.target.value;
    setCartItems(updatedCartItems);
    setCartItemsToStorage(updatedCartItems);
  };

  const [quantity, setQuantity] = useState(cartItems[id].quantity);
  const valueOfInput = quantity > 0 ? quantity : 0;

  const decrementQuantity = () => {
    if (quantity > 0) {
      updateCartItemCount(quantity - 1, id);
      setQuantity(quantity - 1);
    }
  };

  const incrementQuantity = () => {
    updateCartItemCount(quantity + 1, id);
    setQuantity(quantity + 1);
  };

  const caracteristiques = JSON.parse(cartItems[id].specSelected)

  const prodImages = JSON.parse(prod_images);

  return (
    <div key={'cartItem' + nom} className='cartItemParent'>
      <div className="cartItem-nom">
        <p>
          <b>{nom}</b>
        </p>
      </div>
      <div className="cartItem">
        <img  src={prodImages.length > 0 ? API_URL + prodImages[0].image : null} alt="" key={'cartItemImage' + nom} />
        <div className="description" style={{ marginLeft: '20px' }} key={'cartItemDesription' + nom}>
          Price:
          {
            (original_price === actual_price) ?
              ' $' + original_price :
              (
                <>
                  <div>
                    <label style={{ textDecoration: 'line-through', marginLeft: '10px' }}>${original_price}</label>
                  </div>
                  <div style={{ marginTop: '-12px' }}>
                    <label style={{ marginLeft: '10px' }}>${actual_price}</label>
                  </div>
                </>
              )
          }
          <div className="countHandler" style={{marginTop:'15px'}}>
            <button onClick={decrementQuantity}> - </button>
              <input
                value={valueOfInput}
                onChange={(e) => {
                  const newQuantity = Number(e.target.value);
                  if (!isNaN(newQuantity) && newQuantity >= 0) {
                    updateCartItemCount(quantity, id);
                    setQuantity(newQuantity);
                  }
                }}
              />
            <button onClick={incrementQuantity}> + </button>
          </div>
          <div style={{ marginTop: '10px' }}><Button variant="danger" onClick={() => removeProductFromCart(props.data)} >Remove</Button></div>

        </div>

      </div>
      {
        (is_vente_groupee === "true") ?
          (
            <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '40px' }}>
              <legend style={{ fontSize: '17px' }}>Command Type:</legend>
              <div style={{ marginTop: '-5px' }}>
                <input
                  type="radio"
                  name={`commandType-${id}`}
                  id={`commandClassic-${id}`}
                  value="Classic"
                  checked={commandType === 'Classic'}
                  onChange={handleCommandTypeChange}
                />
                <label style={{ fontSize: '14px', marginLeft: '5px', marginRight: '20px' }} htmlFor={`commandClassic-${id}`}>Classic</label>
                <input
                  type="radio"
                  name={`commandType-${id}`}
                  id={`commandCommit-${id}`}
                  value="Commit"
                  checked={commandType === 'Commit'}
                  onChange={handleCommandTypeChange}
                />
                <label style={{ fontSize: '14px', marginLeft: '5px' }} htmlFor={`commandCommit-${id}`}>Commit</label>
              </div>
            </div>
          ) : null
      }
      <div style={{ marginTop: '10px', marginBottom: '20px', marginLeft: '40px' }} >
        {
          (caracteristiques.length > 0) ?
            (
              <>
                <legend style={{ fontSize: '17px' }}>Caractéristique:</legend>
                <div style={{ marginTop: "-8px" }}>
                  {caracteristiques.map(item => `${item.code}: ${item.valeur}`).join(', ')}
                </div>
              </>
            ) : null
        }
      </div>
    </div>
  );
};
