import React, { useState, useEffect, useContext, useRef  } from "react";
import { Link } from "react-router-dom";
import { ShoppingCart } from "phosphor-react";
import "./navbar.css";
import { Button, Modal, Form, FormControl, NavDropdown } from 'react-bootstrap';
import axios from "axios";
import { API_URL, setCustomer, getCustomer } from "../variables";
import { ShopContext } from "../context/shop-context";
import Cart from "../pages/cart/cart";
import { useNavigate } from "react-router-dom";
import { BsSearch } from 'react-icons/bs';
import useTypeData from "../use-types-data";
import { setAmountToPay } from "../variables";
import { FaFacebook } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { FaCaretDown } from 'react-icons/fa';
import { FaCaretUp } from 'react-icons/fa';
import SeparatorV from "./separator-v";
import useTrustTextsData from "../use-trust-texts-data";
import { getAllProducts, setResultProducts } from "../variables";

export const Navbar = () => {
  const [username, setUsername] = useState(null);

  const [isMenuHovered, setIsMenuHovered] = useState(false);

  const isMobile = window.innerWidth <= 768;

  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleMobileNavbar = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const handleMenuEnter = () => {
    setIsMenuHovered(true);
  }

  const handleMenuLeave = () => {
    setIsMenuHovered(false);
  }

  const [hoveredDropdown, setHoveredDropdown] = useState(null);

  const handleMenuCategoryToggle = (index) => {
    setHoveredDropdown(index);
  };

  const [navbarPosition, setNavbarPosition] = useState('absolute');

  const [activeAccordion, setActiveAccordion] = useState(-1);

  const toggleAccordion = (index) => {
    setActiveAccordion(index === activeAccordion ? -1 : index);
  };

  const searchInputRef = useRef(null);

  const customerData = getCustomer();
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(customerData.billing_country);
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] = useState(customerData.delivery_country);

  const { getCartItemsCount } = useContext(ShopContext);

  const storedUsername = localStorage.getItem('username');

  const { types } = useTypeData();
  let categoryDataTemp = '';
  let indexTemp = 0;
  let currentLine = [];

  const { trustTexts } = useTrustTextsData();

  useEffect(() => {
    // Check if the username is stored in local storage
    if (storedUsername !== null) {
      setUsername(storedUsername);
      localStorage.setItem('username', storedUsername);
    }
    else {
      localStorage.removeItem('username');
    }
  }, [storedUsername]);

  const handleBillingCountryChange = (event) => {
    setSelectedBillingCountry(event.target.value);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop >= 40) {
        setNavbarPosition('fixed');
      } else {
        setNavbarPosition('absolute');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDeliveryCountryChange = (event) => {
    setSelectedDeliveryCountry(event.target.value);
  };

  const [showModalSignUp, setShowModalSignUp] = useState(false);

  const handleModalSignUp = () => {
    setShowModalSignUp(!showModalSignUp);
  }

  const [showModalSearch, setShowModalSearch] = useState(false);

  const handleModalSearch = () => {
    setShowModalSearch(!showModalSearch);
    if(!showModalSearch){
      const searchQuery = searchInputRef.current.value;
      
      const products = getAllProducts();

      const results = products.filter(product =>
        product.nom.toLowerCase().includes(searchQuery.toLowerCase()) 
      );

      setResultProducts(results);
      
      navigate(`/search/${searchQuery}`)
    }
  }

  // Register new user
  const handleRegister = async (value) => {
    const username = document.getElementById('username').value;
    const password = document.getElementById('password').value;
    const email = document.getElementById('email').value;

    axios.post(API_URL + "api/create-new-user/", {
      username: username,
      password: password,
      email: email
    })
      .then(response => {
        localStorage.setItem('username', username);
        const data_str = "[" + response.data + "]"
        const json_data = JSON.parse(data_str);

        if (json_data[0].message === "ok") {
          const customer = json_data[0].customer

          setUsername(username);
          setCustomer(customer);

          setShowModalSignUp(false);
        }
        else {
          const data_str = "[" + response.data + "]"
          const json_data = JSON.parse(data_str);

          if (json_data[0].message === "Email already registered") {
            alert(`Failed to create new user ! \nEmail already registered.`);
          }
        }
      })
      .catch(error => {
        alert(`Failed to create user !`);
      });
  }

  const [showModalSignIn, setShowModalSignIn] = useState(false);

  const handleModalSignIn = () => {
    setShowModalSignIn(!showModalSignIn);
  }

  // sign in user
  const handleValidateSignIn = async () => {
    const email = document.getElementById('emailLogin').value;
    const password = document.getElementById('passwordLogin').value;

    axios.post(API_URL + "api/signin/", {
      email: email,
      password: password
    })
      .then(response => {
        const data_str = "[" + response.data + "]"
        const json_data = JSON.parse(data_str);

        if (json_data[0].message === "ok") {
          const customer = json_data[0].customer

          localStorage.setItem('username', customer.username);
          localStorage.setItem('emailLogged', email);
          setUsername(customer.username);
          setCustomer(customer);

          setShowModalSignIn(false);
        }
        else {
          alert(`Failed to authenticate user !`);
        }
      })
      .catch(error => {
        console.log(`Failed to authenticate user !`, error);
      });
  }

  const [showModalCustInfo, setShowModalCustInfo] = useState(false);

  const handleModalCustInfo = () => {
    setShowModalCustInfo(!showModalCustInfo);
  }


  const handleLogout = () => {
    setUsername(null);
    localStorage.removeItem('username');
  }

  const [showModalCart, setShowModalCart] = useState(false);

  const handleModalCart = () => {
    setShowModalCart(!showModalCart);
  }

  // Update customer info
  const handleUpdateInfo = async () => {
    let nom;
    if (document.getElementById('lastNameInfo').value !== '') {
      nom = document.getElementById('lastNameInfo').value;
    }
    else {
      nom = customerData.nom
    }

    let prenom;
    if (document.getElementById('firstNameInfo').value !== '') {
      prenom = document.getElementById('firstNameInfo').value;
    }
    else {
      prenom = customerData.prenom
    }

    let telephone;
    if (document.getElementById('phoneInfo').value !== '') {
      telephone = document.getElementById('phoneInfo').value;
    }
    else {
      telephone = customerData.telephone
    }

    let billingStreetInfo;
    if (document.getElementById('billingStreetInfo').value !== '') {
      billingStreetInfo = document.getElementById('billingStreetInfo').value;
    }
    else {
      billingStreetInfo = customerData.billing_street
    }

    let billingCityInfo;
    if (document.getElementById('billingCityInfo').value !== '') {
      billingCityInfo = document.getElementById('billingCityInfo').value;
    }
    else {
      billingCityInfo = customerData.billing_city
    }

    let billingCountryInfo;
    if (document.getElementById('billingCountryInfo').value !== '') {
      billingCountryInfo = document.getElementById('billingCountryInfo').value;
    }
    else {
      billingCountryInfo = customerData.billing_country
    }

    let billingPostalCodeInfo;
    if (document.getElementById('billingPostalCodeInfo').value === '') {
      if (customerData.billing_postal_code === null) {
        billingPostalCodeInfo = 0;
      }
      else {
        billingPostalCodeInfo = customerData.billing_postal_code;
      }
    }
    else {
      billingPostalCodeInfo = document.getElementById('billingPostalCodeInfo').value;
    }

    let deliveryStreetInfo;
    if (document.getElementById('deliveryStreetInfo').value !== '') {
      deliveryStreetInfo = document.getElementById('deliveryStreetInfo').value;
    }
    else {
      deliveryStreetInfo = customerData.billing_street
    }

    let deliveryCityInfo;
    if (document.getElementById('deliveryCityInfo').value !== '') {
      deliveryCityInfo = document.getElementById('deliveryCityInfo').value;
    }
    else {
      deliveryCityInfo = customerData.delivery_city
    }

    let deliveryCountryInfo;
    if (document.getElementById('deliveryCountryInfo').value !== '') {
      deliveryCountryInfo = document.getElementById('deliveryCountryInfo').value;
    }
    else {
      deliveryCountryInfo = customerData.delivery_country
    }

    let deliveryPostalCodeInfo;
    if (document.getElementById('deliveryPostalCodeInfo').value === '') {
      if (customerData.delivery_postal_code === null) {
        deliveryPostalCodeInfo = 0;
      }
      else {
        deliveryPostalCodeInfo = customerData.delivery_postal_code;
      }
    }
    else {
      deliveryPostalCodeInfo = document.getElementById('deliveryPostalCodeInfo').value;
    }

    axios.put(API_URL + "api/customer/cust/" + customerData.id + "/", {
      "user": customerData.user,
      "nom": nom,
      "prenom": prenom,
      "telephone": telephone,
      "delivery_street": deliveryStreetInfo,
      "delivery_city": deliveryCityInfo,
      "delivery_country": deliveryCountryInfo,
      "delivery_postal_code": deliveryPostalCodeInfo,
      "billing_street": billingStreetInfo,
      "billing_city": billingCityInfo,
      "billing_country": billingCountryInfo,
      "billing_postal_code": billingPostalCodeInfo,
    })
      .then(response => {
        setShowModalCustInfo(false);
        setCustomer(response.data);
        alert(`Update success !`);
      })
      .catch(error => {
        alert(`Failed to update ! `, error);
      });
  }

  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    fetch('https://restcountries.com/v2/all')
      .then(response => response.json())
      .then(data => {
        const countries = data.map(country => ({ code: country.alpha3Code, name: country.name }));
        const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));
        setCountryList(sortedCountries);
      })
      .catch(error => console.error(error));
  }, []);

  const navigate = useNavigate();
  const { cartItems, checkout } = useContext(ShopContext);

  // Calculate the total amount in the cart
  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item].quantity > 0) {
        if (cartItems[item].original_price === cartItems[item].actual_price)
          totalAmount += cartItems[item].quantity * cartItems[item].original_price;
        else
          totalAmount += cartItems[item].quantity * cartItems[item].actual_price;
      }
    }
    return totalAmount;
  };

  const totalAmount = getTotalCartAmount();

  return (
    <div style={{width:'100%'}}>
      {/* Hamburger menu */}
      {isMobile && (<div
      className="container"
        style={{ position: 'fixed', 
                top: '0', 
                paddingTop: '5px',
                paddingLeft: '5px', 
                zIndex: '120',
                width:'100% !important',
                height: '45px',
                backgroundColor: 'white' }}
        >
        <div onClick={toggleMobileNavbar}><FaBars size={25}/></div>
        <div >
                <Link to="/"><h5 style={{ color: 'black', fontWeight: 'bolder' }}>LOGO</h5></Link>
              </div>
              <div className="links-mobile" id='headerCartAndUser'>
                <Link onClick={handleModalCart}>
                  <ShoppingCart size={28} />
                </Link>
                {getCartItemsCount() > 0 ? (<div id="cartCount">{getCartItemsCount()}</div>) : null}
                {(storedUsername !== null) ?
                  (
                    <NavDropdown
                      title={username}
                      show={isMenuHovered} // render the dropdown div if the menu is open OR the mouse is currently hovering over the menu
                      onMouseEnter={handleMenuEnter}
                      onMouseLeave={handleMenuLeave}
                      drop="bottom"
                    >
                      <div style={{
                        position: 'absolute',
                        zIndex: '100',
                        marginTop: '-20px',
                        minWidth: '160px',
                        width: 'auto',
                        height: 'auto',
                        backgroundColor: 'white',
                        border: '1px solid black'
                      }}>
                        <NavDropdown.Item onClick={handleModalCustInfo} className="navDropdownItem">Compte</NavDropdown.Item>
                        <NavDropdown.Item className="navDropdownItem" onClick={() => {navigate("/suivi-commande") }} >Suivi commande</NavDropdown.Item>
                        {(storedUsername !== null) ? (
                          <NavDropdown.Item onClick={() => { navigate("/ticket") }} className="navDropdownItem">Ticket</NavDropdown.Item>
                        ) : null}
                        <NavDropdown.Item onClick={handleLogout} className="navDropdownItem">Logout</NavDropdown.Item>
                      </div>
                    </NavDropdown>) :
                  (<div><Link onClick={() => { handleModalSignIn(); toggleMobileNavbar(); }}>Sign In</Link>
                    <Link onClick={() => { handleModalSignUp(); toggleMobileNavbar(); }}>Sign Up</Link></div>)
                }
              </div>
      </div>)}

      {/* Render code for other devices (desktop) */}
      {!isMobile && (
        <div id="navbarContainer">
          <div
            id="headerTop"
            className="col-12 navbar"
            style={{
              top: '0',
              position: 'fixed',
              zIndex: '99',
              height: '40px',
              backgroundColor: '#103959',
              color: 'white'
            }}
          >
            <div className="col-md-9 col-12" style={{ marginTop: '-5px' }}>
              <FaFacebook size={20} />
              <FaTwitter size={20} style={{ marginLeft: '20px' }} />
              <FaEnvelope size={20} style={{ marginLeft: '20px' }} />
            </div>
            <div className="col-md-3 col-12" id="headerTopItemDiv">
              <Link to="/guides" id="headerTopItem" style={{ color: 'white', marginLeft: '100px', fontSize: '14px' }}>Guides</Link>
              <Link to="/blog" id="headerTopItem" style={{ color: 'white', marginLeft: '20px', fontSize: '14px' }}>Blog</Link>
              <Link to="/aide-faq" id="headerTopItem" style={{ color: 'white', marginLeft: '20px', fontSize: '14px' }}>Aide/FAQ</Link>
            </div>
          </div>
          <div
            className="navbar"
            style={{
              position: 'fixed',
              top: '40px',
              width: '100%',
              zIndex: '99'
            }}
          >
            <Form className="search-container" style={{ width: '15%' }}>
              <div className="search-input-container">
                <FormControl 
                  type="text" 
                  placeholder="Search" 
                  className="search-input"
                  ref={searchInputRef} />
                <Button variant="outline-secondary" className="search-button">
                  <BsSearch  onClick={handleModalSearch} />
                </Button>
              </div>
            </Form>
            <div className="d-flex align-items-center justify-content-center text-center" id="headerLogo">
              <Link to="/"><h1 style={{ color: 'black', fontWeight: 'bolder' }}>LOGO</h1></Link>
            </div>
            <div className="links" id='headerCartAndUser'>
              <Link onClick={() => handleModalCart()}>
                <ShoppingCart size={38} />
              </Link>
              {getCartItemsCount() > 0 ? (<div className="d-flex align-items-center justify-content-center text-center" id="cartCount">{getCartItemsCount()}</div>) : null}
              {(storedUsername !== null) ?
                (
                  <NavDropdown
                    title={username}
                    show={isMenuHovered} // render the dropdown div if the menu is open OR the mouse is currently hovering over the menu
                    onMouseEnter={handleMenuEnter}
                    onMouseLeave={handleMenuLeave}
                    drop="bottom"
                    style={{ marginLeft: '0px', paddingLeft: '0px' }}
                  >
                    <div style={{
                      position: 'absolute',
                      zIndex: '100',
                      marginTop: '-20px',
                      minWidth: '160px',
                      width: 'auto',
                      height: 'auto',
                      backgroundColor: 'white',
                      border: '1px solid black'
                    }}>
                      <NavDropdown.Item onClick={() => handleModalCustInfo()} className="navDropdownItem">Compte</NavDropdown.Item>
                      <NavDropdown.Item className="navDropdownItem" onClick={() => (navigate("/suivi-commande"))} >Suivi commande</NavDropdown.Item>
                      {(storedUsername !== null) ? (
                        <NavDropdown.Item onClick={() => navigate("/ticket")} className="navDropdownItem">Ticket</NavDropdown.Item>
                      ) : null}
                      <NavDropdown.Item onClick={() => handleLogout()} className="navDropdownItem">Logout</NavDropdown.Item>
                    </div>
                  </NavDropdown>) :
                (<div><Link onClick={() => handleModalSignIn()}>Sign In</Link>
                  <Link onClick={() => handleModalSignUp()}>Sign Up</Link></div>)
              }
            </div>
          </div>

          <div
            className="d-flex align-items-center justify-content-center text-center links"
            style={{
              position: 'fixed',
              top: '120px',
              width: '100%',
              zIndex: '98',
              height: '40px',
              backgroundColor: 'white',
              borderTop: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              padding: '30px'
            }}
          >
            <Link to="/shop"> Shop </Link>
            {
              (types.length > 0) ?
                (
                  <>
                    {types.map((type, index) => {

                      const navDropdown = (
                        <NavDropdown.Item onClick={() => { navigate(`/${type.category.nom}/${type.nom}`) }} className="navDropdownItem" key={'menuItem' + type.nom}>
                          {type.nom}
                        </NavDropdown.Item>
                      );
                      if (type.category.nom === categoryDataTemp) {
                        currentLine.push(navDropdown);
                      } else {
                        const line = (
                          (categoryDataTemp !== '') ?
                            <NavDropdown
                              title={categoryDataTemp}
                              show={hoveredDropdown === index}
                              onMouseEnter={() => handleMenuCategoryToggle(index)}
                              onMouseLeave={() => handleMenuCategoryToggle(null)}
                              drop="bottom"
                              style={{ marginLeft: '0px', paddingLeft: '0px' }}
                              key={'menu' + categoryDataTemp}
                            >
                              <div style={{
                                position: 'absolute',
                                zIndex: '100',
                                marginTop: '-20px',
                                minWidth: '160px',
                                width: 'auto',
                                height: 'auto',
                                backgroundColor: 'white',
                                border: '1px solid black'
                              }}>
                                {currentLine}
                              </div>
                            </NavDropdown>
                            : null
                        );
                        currentLine = [navDropdown];
                        categoryDataTemp = type.category.nom;
                        return line;
                      }

                    })}
                    {/* Render the last line */}
                    <NavDropdown
                      title={categoryDataTemp}
                      show={hoveredDropdown === types.length}
                      onMouseEnter={() => handleMenuCategoryToggle(types.length)}
                      onMouseLeave={() => handleMenuCategoryToggle(null)}
                      drop="bottom"
                      style={{ marginLeft: '0px', paddingLeft: '0px' }}
                      key={'menu' + categoryDataTemp}
                    >
                      <div style={{
                        position: 'absolute',
                        zIndex: '100',
                        marginTop: '-20px',
                        minWidth: '160px',
                        width: 'auto',
                        height: 'auto',
                        backgroundColor: 'white',
                        border: '1px solid black'
                      }}>
                        {currentLine}
                      </div>
                    </NavDropdown>
                  </>
                ) :
                null
            }
          </div>

          <div style={{ display: 'flex', height: 'auto', marginTop: '180px', padding: '15px', width: '100%', backgroundColor: '#f7fbfd', color: 'black' }}>
            {trustTexts.map((trustText, index) => (
              <React.Fragment key={'trustTextHeader' + index}>
                <div className="align-items-center justify-content-center text-center" style={{ width: '100%' }}>
                  <div><h6>{trustText.title}</h6></div>
                  <div style={{ marginTop: '-3px' }}>{trustText.description}</div>
                </div>
                {index !== trustTexts.length - 1 && <SeparatorV />}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}

      {isMobile && (
        <div className={`mobile-navbar ${isMobileOpen ? 'open' : ''}`}>
          <div className="modal-overlay" onClick={toggleMobileNavbar} />
          <div className="modal-content">
            <div id="headerTop">
              <div className="col-12" id="headerTopItemDiv">
                <Link to="/guides" id="headerTopItem" onClick={toggleMobileNavbar}>Guides</Link>
                <Link to="/blog" id="headerTopItem" onClick={toggleMobileNavbar}>Blog</Link>
                <Link to="/aide-faq" id="headerTopItem" onClick={toggleMobileNavbar}>Aide/FAQ</Link>
              </div>
            </div>
            <div className="search-div">
              <Form className="search-container" style={{ width: '15%' }}>
                <div className="search-input-container">
                  <FormControl 
                    type="text" 
                    placeholder="Search" 
                    className="search-input"
                    ref={searchInputRef} />
                  <Button variant="outline-secondary" className="search-button">
                    <BsSearch onClick={handleModalSearch}/>
                  </Button>
                </div>
              </Form>
            </div>

            <div className="links-menu">
              <Link to="/shop" onClick={toggleMobileNavbar}> Shop </Link>
              {
                (types.length > 0) ?
                  (
                    <>
                      {types.map((type, index) => {

                        const navDropdown = (
                          <div style={{padding:'3px'}} onClick={() => { navigate(`/${type.category.nom}/${type.nom}`); toggleMobileNavbar(); }} className="navDropdownItem" key={'menuItem' + type.nom}>
                            {type.nom}
                          </div>
                        );
                        if (type.category.nom === categoryDataTemp) {
                          currentLine.push(navDropdown);
                        } else {
                          const line = (
                            (categoryDataTemp !== '') ?
                              <div key={'menu' + categoryDataTemp}>
                                <div id='menu-content' className="row" style={{marginLeft:'20px'}} onClick={() => toggleAccordion(index)}>
                                  {categoryDataTemp}
                                  <div>{activeAccordion === index ? <FaCaretUp /> : <FaCaretDown />}</div>
                                </div>
                                <div style={{marginLeft:'40px'}} className={`${activeAccordion === index ? "" : "hide"}`}>
                                  {currentLine}
                                </div>
                              </div>
                              : null
                          );
                          currentLine = [navDropdown];
                          categoryDataTemp = type.category.nom;
                          indexTemp = index;
                          return line;
                        }

                      })}
                      {/* Render the last line */}
                      <div
                        key={'menu' + categoryDataTemp}
                      >
                        <div id='menu-content' className="row" style={{marginLeft:'20px'}} onClick={() => toggleAccordion(indexTemp + 1)}>
                          {categoryDataTemp}
                          <div>{activeAccordion === indexTemp + 1 ? <FaCaretUp /> : <FaCaretDown />}</div>
                        </div>
                        <div style={{marginLeft:'40px'}} className={`${activeAccordion === indexTemp + 1 ? "" : "hide"}`}>
                          {currentLine}
                        </div>
                      </div>
                    </>
                  ) :
                  null
              }
            </div>
          </div>
        </div>
      )}


      {/* SIGN UP modal */}
      <Modal show={showModalSignUp} onHide={handleModalSignUp}>
        <Modal.Header closeButton>
          <Modal.Title>SIGN UP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            {/*<button className="google-signin2" onClick={() => handleRegister("google")}>
              Sign up with Google
            </button>*/}

            <div style={{ marginTop: '25px' }}>
              <label>Username </label>
            </div>
            <div>
              <input type={'text'} id="username" required></input>
            </div>
            <div style={{ marginTop: '25px' }}>
              <label>Password </label>
            </div>
            <div>
              <input type={'password'} id="password" required></input>
            </div>
            <div style={{ marginTop: '25px' }}>
              <label>E-mail </label>
            </div>
            <div>
              <input type={'email'} id="email" required></input>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalSignUp}>
            Close
          </Button>
          <Button variant="primary" onClick={handleRegister}>
            Register
          </Button>
        </Modal.Footer>
      </Modal>

      {/* SIGN IN modal */}
      <Modal show={showModalSignIn} onHide={handleModalSignIn}>
        <Modal.Header closeButton>
          <Modal.Title>SIGN IN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            <div>
              <label>Email </label>
            </div>
            <div>
              <input type={"email"} id="emailLogin" required></input>
            </div>
            <div style={{ marginTop: '25px' }}>
              <label>Password </label>
            </div>
            <div>
              <input type={'password'} id="passwordLogin" required></input>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalSignIn}>
            Close
          </Button>
          <Button variant="primary" onClick={handleValidateSignIn}>
            Validate
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Customer Informations modal */}
      <Modal show={showModalCustInfo} onHide={handleModalCustInfo} id="modalCompte">
        <Modal.Header closeButton>
          <Modal.Title>CUSTOMER INFORMATIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ marginLeft: '40px', fontSize: '14px' }}>
            <div>
              <label style={{ width: '150px' }}>Username </label>
              <label>{username}</label>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Email </label>
              <label>{localStorage.getItem('emailLogged')}</label>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Last Name </label>
              <input type={'text'} id="lastNameInfo" placeholder={customerData.nom}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>First Name </label>
              <input type={'text'} id="firstNameInfo" placeholder={customerData.prenom}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Phone </label>
              <input type={'text'} id="phoneInfo" placeholder={customerData.telephone}></input>
            </div>
            <div style={{ borderTop: '1px solid #ccc', marginRight: '40px', marginTop: '20px', marginBottom: '20px' }}></div>
            <h5>Billing Address</h5>
            <div style={{ marginTop: '5px' }}>
              <label style={{ width: '150px' }}>Street</label>
              <input type={'text'} id="billingStreetInfo" placeholder={customerData.billing_street}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>City</label>
              <input type={'text'} id="billingCityInfo" placeholder={customerData.billing_city}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Country</label>
              <select id="billingCountryInfo" style={{ width: '170px' }} value={selectedBillingCountry === null ? "" : selectedBillingCountry} onChange={handleBillingCountryChange}>
                {countryList.map((country) => (
                  <option key={'billingCountry' + country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Postal Code</label>
              <input type={'number'} id="billingPostalCodeInfo" placeholder={customerData.billing_postal_code}></input>
            </div>
            <div style={{ borderTop: '1px solid #ccc', marginRight: '40px', marginTop: '20px', marginBottom: '20px' }}></div>
            <h5>Delivery Address</h5>
            <div style={{ marginTop: '5px' }}>
              <label style={{ width: '150px' }}>Street</label>
              <input type={'text'} id="deliveryStreetInfo" placeholder={customerData.delivery_street}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>City</label>
              <input type={'text'} id="deliveryCityInfo" placeholder={customerData.delivery_city}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Country</label>
              <select id="deliveryCountryInfo" style={{ width: '170px', fontSize: '14px', color: '#000' }} value={selectedDeliveryCountry === null ? "" : selectedDeliveryCountry} onChange={handleDeliveryCountryChange}>
                {countryList.map((country) => (
                  <option key={'deliveryCountry' + country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Postal Code</label>
              <input type={'number'} id="deliveryPostalCodeInfo" placeholder={customerData.delivery_postal_code}></input>
            </div>
            <br></br>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCustInfo}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateInfo}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cart Modal */}
      <Modal
        show={showModalCart}
        onHide={handleModalCart}
        id='modalCart'>
        <Modal.Header closeButton>
          <Modal.Title>Your Cart Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cart />
        </Modal.Body>
        <Modal.Footer style={{backgroundColor:'white'}}>
          {totalAmount > 0 ? (
            <div className="checkout">
              <p> Subtotal: ${totalAmount} </p>
              <button
                onClick={() => {
                  setShowModalCart(false);
                }}> Continue Shopping </button>
              <button
                onClick={() => {
                  checkout();
                  setShowModalCart(false);
                  setAmountToPay(totalAmount);
                  navigate("/checkout", { state: { fromButton: true } });
                }}
              >
                {" "}
                Checkout{" "}
              </button>
            </div>
          ) : (
            <div style={{ marginRight: '80px' }}><h4>Your Shopping Cart is Empty</h4></div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
