import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useOrders = (customerId) => {
  const [orderData, setOrderData] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/order/order/by-customer/"+customerId+"/").then(response => response.json())
    ])
      .then(data => {
        const orders = data[0];

        setOrderData(orders);
        
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  
  return { orderData };
};

export default useOrders;