import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/navbar";
import { Home } from "./pages/shop/home";
import { Shop } from "./pages/shop/shop";
import { ShopContextProvider } from "./context/shop-context";
import { ProductDetails } from "./pages/shop/product-details";
import { CheckoutContact } from "./pages/checkout/checkout-contact";
import { Payment } from "./pages/checkout/payment";
import { SuiviCommande } from "./pages/suivi-commande/suivi-commande";
import { TypePage } from "./pages/shop/type-page";
import { CategoryPage } from "./pages/shop/category-page";
import { BrandPage } from "./pages/shop/brand-page";
import { Community } from "./pages/community/community";
import { Blog } from "./pages/community/blog";
import { BlogDetails } from "./pages/community/blog-details";
import { Guides } from "./pages/community/guides";
import { AideFAQ } from "./pages/community/aide-faq";
import { Footer } from "./components/footer";
import { Ticket } from "./pages/ticket";
import { SearchPage } from "./pages/shop/search-page";

function App() {
  return (
    <div className="App">
      <ShopContextProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product-details" element={<ProductDetails />} />
            <Route path="/checkout" element={<CheckoutContact />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/suivi-commande" element={<SuiviCommande />} />
            <Route path="/:category/:type" element={<TypePage/>} />
            <Route path="/category/:category" element={<CategoryPage/>} />
            <Route path="/brand/:brand" element={<BrandPage/>} />
            <Route path="/community" element={<Community/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/blog/:blogdetails" element={<BlogDetails/>} />
            <Route path="/ticket" element={<Ticket/>} />
            <Route path="/guides" element={<Guides/>} />
            <Route path="/aide-faq" element={<AideFAQ/>} />
            <Route path="/search/:searchquery" element={<SearchPage/>} />
          </Routes>
          <Footer />
        </Router>
      </ShopContextProvider>
    </div>
  );
}

export default App;
