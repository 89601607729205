import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'html-react-parser';
import { getProductData, getProdRating, getProdNbRating } from '../../../variables';
import ReactStars from 'react-rating-stars-component'
import useCustomers from "../../../use-customers-data";

function Reviews() {
    const prodData = getProductData();
    let productRating = getProdRating();
    let productNbRating = getProdNbRating();
    const [loading, setLoading] = useState(true);

    const { customerData, ratingData }  = useCustomers(prodData.id);

    useEffect(() => {
        setLoading(false);
      }, [customerData, ratingData]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
          <div className='row d-flex align-items-center justify-content-center text-center' >
                <div style={{width:'100%'}}><p style={{fontSize:'30px',fontWeight:'bolder'}}>ALL Customers reviews are here</p></div>
                <div style={{width:'100%'}}>
                    <p style={{fontSize:'900%', fontWeight:'bolder', marginLeft:'10px'}}>{productRating}</p>
                </div>
                <div className='d-flex align-items-center justify-content-center text-center' style={{width:'100%', marginTop:'-50px'}}>
                    <ReactStars
                        activeColor="#ffd700"
                        count={5}
                        size={23}
                        isHalf={true}
                        value={Number(productRating)}
                        edit={false}
                    />
                </div>
                <div style={{width:'100%'}}>
                    <p style={{fontSize:'20px', fontWeight:'bolde', marginLeft:'10px'}}>Total of customer reviews : {productNbRating}</p>
                </div>
            </div>

            {/* Revies div */}
            <div className='col d-flex' style={{marginTop:'6%', paddingLeft:'12%'}}>
                <div>
                    <div><p style={{fontWeight: 'bolder', fontSize:'200%'}}>REVIEWS</p></div>                        
                    <div>
                        {ratingData.map((data, index) => {
                            let customer = customerData.find((cust) => cust.url === data.customer);
                            return (
                                <div style={{ marginBottom: '40px' }} key={'reviewDiv'+index}>
                                    <span key={'reviewUserneme'+index} style={{fontWeight:"bold", fontStyle:'italic'}}>{customer.user.username}</span><br></br>
                                    <span key={'avis'+index}>{ReactHtmlParser(data.avis)}</span>
                                    <div>
                                        <ReactStars
                                            activeColor="#ffd700"
                                            count={5}
                                            size={15}
                                            isHalf={true}
                                            value={Number(data.rating)}
                                            edit={false}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reviews;