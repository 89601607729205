import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import { getProductData, getProductSpecs } from '../../../variables'; 

function Details() {
    const prodSpecs = getProductSpecs();
    const prodData = getProductData();

    let specCodeTemp = "";
    let returnText = "";

    return (
        <div>
            <div className='row' style={{ paddingLeft:'12%'}}>
                <div className='col-md-7 col-12 d-flex'>
                    <div>
                        <div><p style={{fontWeight: 'bolder', fontSize:'25px'}}>SPECS</p></div>                        
                        <div>
                            {ReactHtmlParser(prodData.specification)}
                            <div className='specs' style={{marginTop:"20px"}}>
                                {prodSpecs.map((spec) => {
                                    if(spec.code === specCodeTemp){
                                        returnText = <li key={"spec"+spec.valeur} style={{marginLeft:"40px"}}>{spec.valeur}</li>
                                    }
                                    else{
                                        returnText = <span key={"specs"+spec.code}>{spec.code}: <li key={"spec"+spec.valeur} style={{marginLeft:"40px"}}>{spec.valeur}</li></span>
                                    }
                                    specCodeTemp = spec.code
                                    return returnText;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-5 col-12' style={{paddingRight:'60px'}} id="includedContainer">
                    <div className='row'>
                        <div>
                            {
                                prodData.included !== "" ?
                                (
                                    <div>
                                        <div><p style={{fontWeight: 'bolder', fontSize:'25px'}}>INCLUDED</p></div>                        
                                        <div>{ReactHtmlParser(prodData.included)}</div>
                                    </div>
                                ):null
                            }
                        </div>
                        <div>
                            {/* Shipping div */}
                            {
                                prodData.shipping !== "" ?
                                (
                                    <div style={{marginTop:'60px'}}>
                                        <div>
                                            <div><p style={{fontWeight: 'bolder', fontSize:'25px'}}>SHIPPING</p></div>                        
                                            <div>{ReactHtmlParser(prodData.shipping)}</div>
                                        </div>
                                    </div>
                                ):null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Details;