import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useDeliveryData = () => {
  const [deliveries, setDeliveries] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/order/order-delivery").then(response => response.json())
    ])
      .then(data => {
        const deliveriesData = data[0];
        setDeliveries(deliveriesData);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);
  

  return { deliveries };
};

export default useDeliveryData;