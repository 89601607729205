import React from "react";
import { getResultProducts } from "../../variables";
import { Product } from "./product";

export const SearchPage = () => {
  const products = getResultProducts();
  console.log("products = ",products);

  return (
    <div className="shop">
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>Search</h6>
      </div>
      <div className="shopTitle">
        <h1>Search results</h1>
      </div>
      <div className="products">
        {products.map((product) => (
          <Product key={product.id} data={product} />
        ))}
      </div>
    </div>
  );
}
