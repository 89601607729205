import React, { useContext } from "react";
import { ShopContext } from "../../context/shop-context";
import { CartItem } from "./cart-item";

import "./cart.css";
function Cart(){

  const { cartItems } = useContext(ShopContext);
  const cartItemsArray = Object.values(cartItems);

  
  return (
    <div>
      <div className="cart">
      {cartItemsArray.map((item) => {
          if (item !== undefined) {
            return <CartItem data={item} />;
          }
          else
            return "";
        })}
      </div>
    </div>
  );
};

export default Cart;
