import React from "react";
import ReactHtmlParser  from 'html-react-parser'
import { getBlogData } from "../../variables";

export const BlogDetails = () => {
  const blogData = getBlogData();

  return (
    <div>
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6><a href='/blog' style={{color:'black'}}>Blog</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>{blogData.titre}</h6>
      </div>
      <div style={{paddingLeft:'240px', paddingRight:'240px', width:'100%'}}>
        <div style={{marginTop:'40px'}}>
          <h1>{blogData.titre}</h1>
          <h6>Auteur : {blogData.auteur}</h6>
        </div>
        <div  style={{paddingTop:'40px'}}>
          {ReactHtmlParser(blogData.contenu)}
        </div>
      </div>
    </div>
  );
}
