import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useComments = (photoExtraId) => {
  const [commentData, setCommentData] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/comment/by-photo-extra/"+photoExtraId+"/").then(response => response.json())
    ])
      .then(data => {
        const comments = data[0];
        setCommentData(comments);     
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  });
  
  return { commentData };
};

export default useComments;