import React from "react";
import { useParams } from 'react-router-dom';
import { Product } from "./product";
import useProductByBrandData from "../../use-product-by-brand-data";
import { getBrandDescription } from "../../variables";
import ReactHtmlParser from 'html-react-parser'

export const BrandPage = () => {
  const { brand } = useParams();
  const { productsByBrand } = useProductByBrandData(brand);
  const description = getBrandDescription();

  return (
    <div className="shop">
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>Marque - {brand}</h6>
      </div>
      <div className="shopTitle">
        <h1>{brand}</h1>
      </div>
      <div style={{paddingLeft:'120px', paddingRight:'120px', marginBottom:'120px', whiteSpace: 'pre-line'}}>
        {ReactHtmlParser(description)}
      </div>
      <div className="shopTitle">
          <h3>Produits correspondants</h3>
        </div>
      <div className="products">
        {productsByBrand.map((product) => (
          <Product key={product.id} data={product} />
        ))}
        </div>
    </div>
  );
}
