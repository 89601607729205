import React, { useState, useEffect } from 'react';

const ProgressBar = ({ value, max }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const percent = (value / max) * 100;
    setProgress(percent);
  }, [value, max]);

  const getProgressBarStyle = () => {
    const percent = progress.toFixed(0);
    const color = `green`;
    return { width: `${percent}%`, background: color };
  };

  return (
    <div className="progress-bar" style={{backgroundColor:'white', border:'1px solid #f1f1f1'}}>
      <div className="progress" style={getProgressBarStyle()}></div>
    </div>
  );
};

export default ProgressBar;
