import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useTickets = (customerId) => {
  const [ticketData, setTicketData] = useState([]);
  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/setting/ticket/by-customer/"+customerId+"/").then(response => response.json())
    ])
      .then(data => {
        const tickets = data[0];
        setTicketData(tickets);     
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  });
  
  return { ticketData };
};

export default useTickets;