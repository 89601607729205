import React, { useState, useEffect } from "react";
import { Button, Modal } from 'react-bootstrap';
import axios from "axios";
import { API_URL, getProductData, getCustomer, getProdRating, getProdNbRating } from "../variables";
import ReactStars from 'react-rating-stars-component';
import StyledTextarea from "./styled-textarea";


const Rating = ({ reviewContentRef, handleSetActiveTabAndScroll }) => {
  let prodData = getProductData();
  const customerData = getCustomer();

  let productRating = getProdRating();
  const [rating, setRating] = useState(productRating);

  let productNbRating = getProdNbRating();
  const [nbRating, setNbRating] = useState(productNbRating);

  useEffect(() => {
    setRating(prodData.rating);
    setNbRating(prodData.nb_rating);
  }, [prodData.rating, prodData.nb_rating]);

  const handleMouseEnter = (newRating) => {
    // update the rating only when the user is interacting with the stars
    setRating(newRating);
  };

  const handleMouseLeave = () => {
    // reset the rating to the initial value when the user leaves the stars
    setRating(productRating);
  };

  const handleClick = (newRating) => {
    // update the rating and call the handleRate function
    setRating(newRating);
    handleRate(newRating); 
  };

  const handleRate = (rate) => {
    // check if customer has already rated the product
    const storedUsername = localStorage.getItem('username');
    if(storedUsername !== null) {
        axios
          .get(API_URL + "api/product/rating/by-product/"+prodData.id+"/"+customerData.id+"/")
          .then(function(response) {
            // handle the response here
            if (response.data.length === 0) {
              setRating(rate);
              setShowModalAvis(true); // show the modal
            } 
            else{
              alert("You have already rated this product ! Thank you !")
              window.location.reload();
            }
          })
          .catch(function(error) {
            // handle errors here
            console.error(error);
          });
      }
      else{
        alert("Please sign in !!!")
        window.location.reload();
      }
  };

  const [showModaAvis, setShowModalAvis] = useState(false);

  const handleModalAvis = () => {
    setShowModalAvis(!showModaAvis);
    window.location.reload();
  }

  // Register review
  const handleModalAvisValidate = async (value) => {
    axios
        .get(API_URL + "api/product/rating/by-product/"+prodData.id+"/"+customerData.id+"/")
        .then(function(response) {
          // handle the response here
          if (response.data.length === 0) {
            const avis = document.getElementById('avisTextarea').innerHTML;

            axios
              .post(API_URL + "api/product/rating/", {
                avis: avis,
                rating: rating,
                produit: ""+API_URL+"api/product/prod/"+prodData.id+"/",
                customer: ""+API_URL+"api/customer/cust/"+customerData.id+"/"
              },
              {
                'Content-Type': 'application/json'
              }
              )
              .then(response => {
                setShowModalAvis(false);
                alert('Thank you for making a review !')
                setNbRating(nbRating + 1); // increment number of ratings
                window.location.reload();
                window.location.reload();
              })
              .catch(error => {
                console.error(error);
              });
          } 
        })
        .catch(function(error) {
          // handle errors here
          console.error(error);
        });
  }

  const handleReviewClick = () => {
    handleSetActiveTabAndScroll(2);

    setTimeout(() => {
      reviewContentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500); // Change the delay time as needed
  };

  return (
    <>
      <div className="row" style={{marginLeft:'1px'}}>
        <ReactStars
          activeColor="#ffd700"
          count={5}
          size={23}
          isHalf={true}
          value={ Number(productRating) }
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onChange={handleClick}
        />
        <p style={{fontSize:'16px', color:'blue', fontWeight:'bolder', fontStyle:'italic', marginLeft:'10px', marginTop:'7px', cursor:'pointer'}}
         onClick={handleReviewClick}>
          {productNbRating} reviews
        </p>
      </div>

      {/* GIVE YOUR OPINION modal */}
      <Modal show={showModaAvis} onHide={handleModalAvis}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p>Your rating : {rating}</p>
             GIVE YOUR OPINION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            <div>
              <StyledTextarea />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalAvis}>
            Leave
          </Button>
          <Button variant="primary" onClick={handleModalAvisValidate}>
            Validate
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Rating;