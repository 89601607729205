import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useProductByBrandData = (brand) => {
  const [productsByBrand, setProductsByBrand] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/prod-by-brand/?brand=" + brand).then(response => response.json())
    ])
      .then(data => {
        const dataProd = data[0]
        setProductsByBrand(dataProd);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return { productsByBrand };
};

export default useProductByBrandData;