import React from 'react';
import {
    API_URL,
    getProductData,
    getProductSpecs,
    setProductPhotos,
    setProductData,
    setProductSpecs,
    setPageIsLoaded,
    getProductPhotos,
    setProductRelateds, 
    getAllProducts
} from '../../../variables';
import ReactHtmlParser from 'html-react-parser'
import Separator from '../../../components/separator';
import "./style.css";

function Overview(props) {
    let specCodeTemp = "";
    let returnText = "";

    // Get all product details data
    const prodData = getProductData();
    const prodSpecs = getProductSpecs();
    const products = getAllProducts();
    const { productRelateds } = props;
    const prodImages = getProductPhotos();
    const imageCover = prodImages.find((image) => image.cover === true);

    // Get the number of the product related you might also like this
    let likeThisCount = 0;
    for (let i = 0; i < productRelateds.length; i++) {
        if (productRelateds[i].you_might_also_like === true) {
            likeThisCount++;
        }
    }

    // Go to other product details
    const handleClick = (product) => {
        const prodSpecs = JSON.parse(product.prod_specs);
        const prodImages = JSON.parse(product.prod_images);
        const prodRelateds = JSON.parse(product.prod_relateds);

        // set all products data with details
        setProductPhotos(prodImages);
        setProductSpecs(prodSpecs);
        setProductData(product);
        setProductRelateds(prodRelateds);

        setPageIsLoaded(false);

        // go to product detail page
        window.location.reload();
    };

    return (
        <div>
            {/* summary and description div */}
            <div className='row' style={{ marginBottom: '40px' }}>
                <div className='col-md-7 col-12 d-flex align-items-center justify-content-center text-center'>
                    <div className='container-fluid'>
                        <div>
                            <p style={{ fontSize: '28px', fontWeight: 'bold', paddingLeft: '60px', paddingRight: '60px' }}>{prodData.nom}</p>
                        </div>
                        <div style={{ marginTop: '40px' }}>
                            {
                                (imageCover !== undefined) ?
                                    (<img src={API_URL + imageCover['image']} alt="" style={{ width: '90%', height: '95%' }} />)
                                    :
                                    (<img src={prodImages.length > 0 ? API_URL + prodImages[0]['image'] : null} alt="" style={{ width: '90%', height: '98%' }} />)
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-5 col-12' style={{ paddingRight: '60px' }}  id='description'>
                    <div className='container-fluid'>
                        <div>{ReactHtmlParser(prodData.description)}</div>
                        <div>
                            {
                                (prodSpecs.length > 0) ?
                                    (
                                        <div style={{ marginBottom: '9px', marginTop: '30px' }}>Caractéristiques :</div>
                                    ) : null
                            }
                            {
                                prodSpecs.map((spec) => {
                                    if (spec.code === specCodeTemp) {
                                        returnText = <li key={"spec" + spec.valeur} style={{ marginLeft: "40px" }}>{spec.valeur}</li>
                                    }
                                    else {
                                        returnText = <span key={"specs" + spec.code}>{spec.code}: <li key={"spec" + spec.valeur} style={{ marginLeft: "40px" }}>{spec.valeur}</li></span>
                                    }
                                    specCodeTemp = spec.code
                                    return returnText;
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Separator />

            {/* You might also like this div */}
            {
                likeThisCount > 0 ?
                    (
                        <div>
                            <div className='col d-flex' style={{ marginTop: '60px', paddingLeft: '12%' }}>
                                <div>
                                    <div><p style={{ fontWeight: 'bolder', fontSize: '25px' }}>YOU MIGHT ALSO LIKE THIS</p></div>
                                    <div style={{ width: '100%', marginBottom: '20px', overflowX: 'auto', display: 'flex', flexWrap: 'nowrap' }}>
                                        {productRelateds.map((productRelated) => {
                                            if (productRelated.you_might_also_like === true) {
                                                const product = products.find((produit) => produit.nom === productRelated.produit_nom)
                                                const prodImages = JSON.parse(product.prod_images);

                                                return (
                                                    <div className="productRelated grow" key={'alsoLike' + product.id} onClick={() => handleClick(product)}>
                                                        <div>
                                                            <img src={prodImages.length > 0 ? API_URL + prodImages[0].image : null} alt="" />
                                                        </div>

                                                        <div className="description">
                                                            <p>
                                                                <b>{product.nom}</b>
                                                            </p>
                                                            <p>
                                                                {
                                                                    (Number(product.original_price) <= Number(product.actual_price)) ?
                                                                        '$' + product.original_price :
                                                                        (
                                                                            <>
                                                                                <label style={{ textDecoration: 'line-through' }}>${product.original_price}</label>
                                                                                <label style={{ marginLeft: '10px' }}>${product.actual_price}</label>
                                                                            </>
                                                                        )
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
            }
        </div>
    );
}

export default Overview;