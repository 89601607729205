import React, { useState, useEffect } from 'react';
import "./banner.css"
import usePhotoData from '../use-banners-data';
import ReactHtmlParser from 'html-react-parser';

const Banner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { photos } = usePhotoData();
  let slides = [];

  if (photos.length > 0) {
    for (let i = 0; i < photos.length; i++) {
      if (photos[i].banner_photo === true) {
        const banner_text = photos[i].banner_text !== null ? photos[i].banner_text : ""
        slides.push({
          image: photos[i].image,
          text: banner_text,
        });
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="banner">
      {slides.map((slide, index) => (
        <div
          key={'bannerSlide'+index}
          className={`bannerSlide ${index === currentSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.image})`, height: '450px' }}
        >
          <div className="bannerSlide-content">
            <h1>{ReactHtmlParser(slide.text)}</h1>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Banner;
