import React from "react";
import useBlogs from "../../use-blogs-data";
import "./style.css"
import ReactHtmlParser from 'html-react-parser'

export const AideFAQ = () => {
  const { blogData } = useBlogs();

  return (
    <div>
      <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>Aide/FAQ</h6>
      </div>
      <div style={{ paddingTop: '40px', width: '100%' }}>
        <div className="d-flex align-items-center justify-content-center text-center">
          <h1>Aide/FAQ</h1>
        </div>
        <div className="blogs" style={{ marginTop: '40px' }}>
          {blogData.map((blog, index) => {
            let data = ""
            if (blog.type === "Aide/FAQ") {
              data = (
                <div style={{ paddingTop: '40px', marginLeft: '600px', width: '100%' }}>
                  {ReactHtmlParser(blog.contenu)}
                </div>
              )
            }
            return data;
          }
          )}
        </div>
      </div>
    </div>
  );
}