import React, { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import useCustomers from "../use-customers-data";
import { API_URL, getCustomer } from "../variables";
import useTickets from "../use-tickets-data";
import axios from "axios";
import ReactHtmlParser from 'html-react-parser';

export const Ticket = () => {
    const [showModaTicket, setShowModalTicket] = useState(false);
    const currentCustomer = getCustomer();
    const date = new Date().toISOString().slice(0, 19).replace('T', ' ');

  const { customerData } = useCustomers(0);
  const { ticketData } = useTickets(currentCustomer.id);

  const handleModalTicket = () => {
    setShowModalTicket(!showModaTicket);
  }

  // Register review
  const handleModalTicketValidate = async (value) => {
    const titre = document.getElementById('titreMessage').value;
    const description = document.getElementById('textareaMessage').value;
    
    axios
      .post(API_URL + "api/setting/ticket/", {
        title: titre,
        date: date,
        description: description,
        customer: "" + API_URL + "api/customer/cust/" + currentCustomer.id + "/"
      },
        {
          'Content-Type': 'application/json'
        }
      )
      .then(response => {
        setShowModalTicket(false);
        alert('New ticket created succesfully !')
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  }

    return (
        <div className="container-fluid" style={{ marginTop: '40px' }}>
          <div className="row" style={{marginLeft:'20px', marginTop:'20px'}}>
              <h6><a href='/' style={{color:'black'}}>Home</a></h6>
              <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
              <h6>Ticket</h6>
          </div>
            <div className="container align-items-center justify-content-center text-center">
                <h4>All your tickets here</h4>
                <div style={{ marginTop: '20px', width: '100%' }}>
                    <Button variant="outline-success" onClick={() => { setShowModalTicket(true) }} >
                        NEW TICKET
                    </Button>
                </div>
            </div>


            {/* Comments div */}
        <div className='col d-flex' style={{ marginTop: '40px', paddingLeft: '12%' }}>
          <div>
            <div><p style={{ fontWeight: 'bolder', fontSize: '200%' }}>TICKETS</p></div>
            <div>
              {ticketData.map((data, index) => {
                let customer = customerData.find((cust) => cust.url === data.customer);

                if (customer !== undefined) {
                  return (
                    <div style={{ marginBottom: '40px' }} key={'ticketDiv' + index}>
                      <span style={{ fontWeight: "bold", fontStyle: 'italic' }}>{customer.user.username}</span><br></br>
                      <div>{data.title}</div>
                      <div>{ReactHtmlParser(data.description)}</div>
                    </div>)
                }
                else { return "" };
              })}
            </div>
          </div>
        </div>


                        {/* GIVE YOUR OPINION modal */}
      <Modal show={showModaTicket} onHide={handleModalTicket}>
        <Modal.Header closeButton>
          <Modal.Title>
             GIVE YOUR MESSAGE
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
          <div>
              <label>Titre </label>
            </div>
            <div>
              <input type={"text"} id="titreMessage"></input>
            </div>
          <div style={{ marginTop: '25px' }}>
              <label>Message </label>
            </div>
            <div>
                <textarea rows="5" cols="33" id="textareaMessage"></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalTicket}>
            Leave
          </Button>
          <Button variant="primary" onClick={handleModalTicketValidate}>
            Validate
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    );
};
