import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_URL, getCustomer, setCustomer, getIdCommande } from '../../variables';
import axios from 'axios';
import "./checkout.css"
import useDeliveryData from '../../use-delivery-data';

export const CheckoutContact = () => {
  const customerData = getCustomer();
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(customerData.billing_country);
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] = useState(customerData.delivery_country);
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(null);
  const idCommande = getIdCommande();
  const storedUsername = localStorage.getItem('username');
  const storedEmail = localStorage.getItem('emailLogged');

  const { deliveries } = useDeliveryData();
  console.log("deliveries = ", deliveries)

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state || !location.state.fromButton) {
      // Redirect to home page if the user didn't click the continue button
      window.location.href = "/";
    }
  }, [location.state]);

  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    fetch('https://restcountries.com/v2/all')
      .then(response => response.json())
      .then(data => {
        const countries = data.map(country => ({ code: country.alpha3Code, name: country.name }));
        const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));
        setCountryList(sortedCountries);
      })
      .catch(error => console.error(error));
  }, []);

  const handleBillingCountryChange = (event) => {
    setSelectedBillingCountry(event.target.value);
  };

  const handleDeliveryCountryChange = (event) => {
    setSelectedDeliveryCountry(event.target.value);
  };

  const handleDeliveryModeChange = (event) => {
    setSelectedDeliveryMode(event.target.value);
  };

  const handleCancel = () => {
    axios
      .put(API_URL + "api/order/order/" + idCommande + "/", {
        status: "Annulée"
      })
      .then(response => {
        console.log("Order Candeled");
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleSubmit = () => {
    let nom;
    if (document.getElementById('lastNameInfo').value !== '') {
      nom = document.getElementById('lastNameInfo').value;
    }
    else {
      nom = customerData.nom
    }

    let prenom;
    if (document.getElementById('firstNameInfo').value !== '') {
      prenom = document.getElementById('firstNameInfo').value;
    }
    else {
      prenom = customerData.prenom
    }

    let telephone;
    if (document.getElementById('phoneInfo').value !== '') {
      telephone = document.getElementById('phoneInfo').value;
    }
    else {
      telephone = customerData.telephone
    }

    let billingStreetInfo;
    if (document.getElementById('billingStreetInfo').value !== '') {
      billingStreetInfo = document.getElementById('billingStreetInfo').value;
    }
    else {
      billingStreetInfo = customerData.billing_street
    }

    let billingCityInfo;
    if (document.getElementById('billingCityInfo').value !== '') {
      billingCityInfo = document.getElementById('billingCityInfo').value;
    }
    else {
      billingCityInfo = customerData.billing_city
    }

    let billingCountryInfo;
    if (document.getElementById('billingCountryInfo').value !== '') {
      billingCountryInfo = document.getElementById('billingCountryInfo').value;
    }
    else {
      billingCountryInfo = customerData.billing_country
    }

    let billingPostalCodeInfo;
    if (document.getElementById('billingPostalCodeInfo').value === '') {
      if (customerData.billing_postal_code === null) {
        billingPostalCodeInfo = 0;
      }
      else {
        billingPostalCodeInfo = customerData.billing_postal_code;
      }
    }
    else {
      billingPostalCodeInfo = document.getElementById('billingPostalCodeInfo').value;
    }

    let deliveryStreetInfo;
    if (document.getElementById('deliveryStreetInfo').value !== '') {
      deliveryStreetInfo = document.getElementById('deliveryStreetInfo').value;
    }
    else {
      deliveryStreetInfo = customerData.billing_street
    }

    let deliveryCityInfo;
    if (document.getElementById('deliveryCityInfo').value !== '') {
      deliveryCityInfo = document.getElementById('deliveryCityInfo').value;
    }
    else {
      deliveryCityInfo = customerData.delivery_city
    }

    let deliveryCountryInfo;
    if (document.getElementById('deliveryCountryInfo').value !== '') {
      deliveryCountryInfo = document.getElementById('deliveryCountryInfo').value;
    }
    else {
      deliveryCountryInfo = customerData.delivery_country
    }

    let deliveryPostalCodeInfo;
    if (document.getElementById('deliveryPostalCodeInfo').value === '') {
      if (customerData.delivery_postal_code === null) {
        deliveryPostalCodeInfo = 0;
      }
      else {
        deliveryPostalCodeInfo = customerData.delivery_postal_code;
      }
    }
    else {
      deliveryPostalCodeInfo = document.getElementById('deliveryPostalCodeInfo').value;
    }

    const deliveryMode = document.getElementById('deliveryMode').value;
    const delivery = deliveries.find((delivery) => delivery.nom === deliveryMode)

    axios.put(API_URL + "api/customer/cust/" + customerData.id + "/", {
      "user": customerData.user,
      "nom": nom,
      "prenom": prenom,
      "telephone": telephone,
      "delivery_street": deliveryStreetInfo,
      "delivery_city": deliveryCityInfo,
      "delivery_country": deliveryCountryInfo,
      "delivery_postal_code": deliveryPostalCodeInfo,
      "billing_street": billingStreetInfo,
      "billing_city": billingCityInfo,
      "billing_country": billingCountryInfo,
      "billing_postal_code": billingPostalCodeInfo,
    })
      .then(response => {
        setCustomer(response.data);

        if(delivery.id > 0){
          axios.put(API_URL + "api/order/order/" + idCommande + "/", {
            "livraison": delivery.id
          })
            .then(response => {
              console.log("Order livraison updated")
            })
            .catch(error => {
              alert(`Failed to update ! `, error);
            });
        }

      })
      .catch(error => {
        alert(`Failed to update ! `, error);
      });
  }

  return (
    <div style={{ paddingLeft: '40px', paddingTop: '40px', width: '100%' }}>
      <div>
        <label style={{ fontSize: '30px' }}>Contact Info</label>
      </div>
      <div>
        {
          (customerData.username !== "") ?
            (
              storedUsername + ' (' + storedEmail + ')'
            ) :
            (
              <input type='email' placeholder="Email Address" />
            )
        }
      </div>
      <div className="container-fluid" style={{ marginBottom: '40px', marginLeft: '-20px' }}>
        <div style={{ borderTop: '1px solid #ccc', marginTop: '20px', marginBottom: '20px' }}></div>
        <div className="row">
          <div className="col-sm-4">
            <div>
              <label style={{ width: '150px' }}>Last Name </label>
              <input type={'text'} id="lastNameInfo" placeholder={customerData.nom}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>First Name </label>
              <input type={'text'} id="firstNameInfo" placeholder={customerData.prenom}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Phone </label>
              <input type={'text'} id="phoneInfo" placeholder={customerData.telephone}></input>
            </div>
          </div>
          <div className="col-sm-4" id="billingInfo">
            <h5>Billing Address</h5>
            <div style={{ marginTop: '5px' }}>
              <label style={{ width: '150px' }}>Street</label>
              <input type={'text'} id="billingStreetInfo" placeholder={customerData.billing_street}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>City</label>
              <input type={'text'} id="billingCityInfo" placeholder={customerData.billing_city}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Country</label>
              <select id="billingCountryInfo" style={{ width: '170px', fontSize: '14px', color: '#000' }} value={selectedBillingCountry} onChange={handleBillingCountryChange}>
                {countryList.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Postal Code</label>
              <input type={'number'} id="billingPostalCodeInfo" placeholder={customerData.billing_postal_code}></input>
            </div>
          </div>
          <div className="col-sm-4" id="deliveryInfo">
            <h5>Delivery Address</h5>
            <div style={{ marginTop: '5px' }}>
              <label style={{ width: '150px' }}>Street</label>
              <input type={'text'} id="deliveryStreetInfo" placeholder={customerData.delivery_street}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>City</label>
              <input type={'text'} id="deliveryCityInfo" placeholder={customerData.delivery_city}></input>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Country</label>
              <select id="deliveryCountryInfo" style={{ width: '170px', fontSize: '14px', color: '#000' }} value={selectedDeliveryCountry} onChange={handleDeliveryCountryChange}>
                {countryList.map((country) => (
                  <option key={country.code} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <br></br>
            <div>
              <label style={{ width: '150px' }}>Postal Code</label>
              <input type={'number'} id="deliveryPostalCodeInfo" placeholder={customerData.delivery_postal_code}></input>
            </div>
          </div>
        </div>
        <div style={{ borderTop: '1px solid #ccc', marginTop: '20px', marginBottom: '20px' }}></div>

        <div className="row">
          <div className="col-sm-4">
            <h5>Mode de livraison</h5>
            <div style={{ marginTop: '20px' }}>
              <select
                id="deliveryMode"
                style={{ width: '170px', fontSize: '14px', color: '#000' }}
                value={selectedDeliveryMode}
                onChange={handleDeliveryModeChange}
              >
                <option value="">Select delivery mode</option>
                {deliveries.map((delivery) => (
                  <option key={delivery.nom} value={delivery.nom}>
                    {delivery.nom}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <h5>Info Livraison</h5>
            <div style={{ marginTop: '5px' }}>
              <label style={{ width: '150px' }}>Transporteur</label>
              <label style={{ width: '150px' }}>
                {deliveries.find((delivery) => delivery.nom === selectedDeliveryMode)?.transporteur}
              </label>
            </div>
            <div>
              <label style={{ width: '150px' }}>Formule</label>
              <label style={{ width: '150px' }}>
                {deliveries.find((delivery) => delivery.nom === selectedDeliveryMode)?.formule}
              </label>
            </div>
            <div>
              <label style={{ width: '150px' }}>Delai</label>
              <label style={{ width: '150px' }}>
                {deliveries.find((delivery) => delivery.nom === selectedDeliveryMode)?.delai_livraison}
              </label>
            </div>
            <div>
              <label style={{ width: '150px' }}>Prix ($)</label>
              <label style={{ width: '150px' }}>
                {deliveries.find((delivery) => delivery.nom === selectedDeliveryMode)?.prix_livraison}
              </label>
            </div>
          </div>
          <div className="col-sm-4"></div>
        </div>

        <div className='row'>
          <button
            type="submit"
            className="btn btn-danger"
            style={{ marginTop: '40px' }}
            onClick={() => {
              handleCancel();
              navigate("/");
            }}
          >
            Annuler
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            style={{ marginTop: '40px', marginLeft: '10px' }}
            onClick={() => {
              handleSubmit();
              navigate("/payment", { state: { fromContactButton: true } });
            }}
          >
            Continuer
          </button>
        </div>
      </div>
    </div>
  );
};