import { useState, useEffect } from "react";
import { API_URL } from "./variables.js";

const useProductByTypeData = (type) => {
  const [productsByType, setProductsByType] = useState([]);

  useEffect(() => {
    Promise.all([
      fetch(API_URL + "api/product/prod-by-type/?type=" + type).then(response => response.json())
    ])
      .then(data => {
        const dataProd = data[0]
        setProductsByType(dataProd);
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return { productsByType };
};

export default useProductByTypeData;