import React, { useState, useContext, useRef, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  API_URL,
  getProductPhotos,
  getProductData,
  setProductPhotos,
  setProductData,
  setProdNbRating,
  setProdRating,
  setProductSpecs,
  getProductSpecs,
  getProductRelateds,
  setProductRelateds,
  getAllProducts,
} from '../../variables';
import "./shop.css";
import { ShopContext } from "../../context/shop-context";
import Separator from '../../components/separator';
import Details from './product-details/details';
import Overview from './product-details/overview';
import Reviews from './product-details/reviews';
import Photos from './product-details/photos';
import { FaFacebook } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import ReactHtmlParser from 'html-react-parser';
import Rating from '../../components/rating';
import { FaHistory } from 'react-icons/fa';
import { Button, Modal } from 'react-bootstrap';
import ProgressBar from '../../components/progress-bar';

export const ProductDetails = () => {
  let specCodeTemp = '';
  let currentLine = [];

  const { addToCart } = useContext(ShopContext);
  const [activeTab, setActiveTab] = useState(0);
  const [index, setIndex] = useState(0);
  const [showModalPriceProgress, setShowModalPriceProgress] = useState(false);

  // Get all product details data
  const prodImages = getProductPhotos();
  const prodData = getProductData();
  const prodSpecs = getProductSpecs();
  const productRelateds = getProductRelateds();
  const products = getAllProducts();

  const [quantity, setQuantity] = useState(1);
  const valueOfInput = quantity > 0 ? quantity : 0;

  const decrementQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  };

  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const [specSelectedArray, setSpecSelectedArray] = useState([]);

  const updateSpecSelectedArray = (code, valeur) => {
    const index = specSelectedArray.findIndex(spec => spec.code === code);
    if (index < 0) {
      setSpecSelectedArray([...specSelectedArray, { code, valeur }]);
    } else {
      const updatedSpecSelectedArray = [...specSelectedArray];
      updatedSpecSelectedArray[index].valeur = valeur;
      setSpecSelectedArray(updatedSpecSelectedArray);
    }
  };

  // define a state variable to store the variant of each button
  const [specButtonVariants, setSpecButtonVariants] = useState(Array(prodSpecs.length).fill('outline-primary'));

  // define a click event handler to change the variant of a button
  const handleSpecClick = (index, code, valeur, photo) => {
    const indexFound = prodImages.findIndex(prodImage => prodImage.image === photo.image);
    const newSpecButtonVariants = specButtonVariants.map((variant, i) => {
      if (i === index) {
        return 'primary'; // set variant of clicked button to primary
      } else if (prodSpecs[i].code === prodSpecs[index].code) {
        return 'outline-primary'; // set variant of other buttons in the same spec to outline-primary
      } else {
        return variant; // keep variant of buttons in different specs unchanged
      }
    });
    setSpecButtonVariants(newSpecButtonVariants);
    updateSpecSelectedArray(code, valeur);
    if (indexFound > -1)
      setIndex(indexFound);
  };

  const priceProgressionByRateStr = prodData.price_progression_by_rate;
  const priceProgressionByRate = priceProgressionByRateStr.split(" - ");

  setProdRating(prodData['rating']);
  setProdNbRating(prodData['nb_rating']);

  // Get the number of the product related you might also like this
  let freqBoughtTogCount = 0;
  for (let i = 0; i < productRelateds.length; i++) {
    if (productRelateds[i].frequently_bought_together === true) {
      freqBoughtTogCount++;
    }
  }

  // Cart context
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleImageClick = (clickedIndex) => {
    setIndex(clickedIndex);
  };

  const reviewContentRef = useRef(null);

  useEffect(() => {
    if (prodSpecs > 0) {
      const firstSpec = prodSpecs[0];
      handleSpecClick(0, firstSpec.code, firstSpec.valeur, firstSpec.prod_photo);
    }
  }, []); // Empty dependency array to run the effect only once

  // All the content of each tab
  const tabContent = [
    { // OVERVIEW tab content
      title: 'OVERVIEW',
      content: (
        <div>
          <Overview productRelateds={productRelateds} />
        </div>
      ),
    },
    {
      title: 'DETAILS',
      content: (
        <div>
          <Details />
        </div>
      ),
    },
    {
      title: 'REVIEWS (' + prodData.nb_rating + ')',
      content: (
        <div ref={reviewContentRef}>
          <Reviews />
        </div>
      ),
    },
    {
      title: 'PHOTOS',
      content: (
        <div>
          <Photos />
        </div>
      ),
    }
  ];

  // Go to other product details
  const handleClick = (product) => {
    const prodSpecs = JSON.parse(product.prod_specs);
    const prodImages = JSON.parse(product.prod_images);
    const prodRelateds = JSON.parse(product.prod_relateds);

    // set all products data with details
    setProductPhotos(prodImages);
    setProductSpecs(prodSpecs);
    setProductData(product);
    setProductRelateds(prodRelateds);

    // go to product detail page
    window.location.reload();
  };

  const handleModalPriceProgress = () => {
    setShowModalPriceProgress(!showModalPriceProgress);
  }

  const handleSetActiveTabAndScroll = (tabIndex) => {
    setActiveTab(tabIndex);
    reviewContentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container-fluid">
      <div className="row" style={{marginLeft:'20px', marginTop:'20px', marginBottom:'20px'}}>
        <h6><a href='/' style={{color:'black'}}>Home</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6><a href='/shop' style={{color:'black'}}>Produits</a></h6>
        <h6 style={{marginLeft:'10px', marginRight:'10px', fontSize:'18px'}}>{'>'}</h6>
        <h6>{prodData.nom}</h6>
      </div>
      <div className='row' id='containerHeader'>
        <div className="col-md-7 col-12" id='containerHeaderLeft'>
          {/* Images carousel on the top */}
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}   // Disable automatic sliding
            id='carousel-img-slide'
          >
            {prodImages.map((prodImage, index) => (
              <Carousel.Item key={'carousel' + index} id='carousel-img-item'>
                <Image src={API_URL + prodImage.image} alt="" fluid />
              </Carousel.Item>
            ))}
          </Carousel>
          {/* Images thumbnail */}
          <Row className="mt-3">
            <div className="thumbnail-scroll-container" >
              {prodImages.map((prodImage, i) => (
                <Col key={'prodimages' + i} sm={2} className="thumbnail-col">
                  <Image
                    src={API_URL + prodImage.image}
                    alt=""
                    fluid
                    className={`img-thumbnail ${i === index ? 'active' : ''
                      } ${i === prodImages.length - 1 ? 'mr-0' : 'mr-5'
                      } ${i === 0 ? 'ml-0' : 'ml-5'}`}
                    onClick={() => handleImageClick(i)}
                  />
                </Col>
              ))}
            </div>
          </Row>
        </div>
        {/* Right side of the top div */}
        <div className="column column-scrollable col-md-5 col-12">

          <div id='product-details-nom'>{prodData.nom}</div>
          <div id='product-details-summary'>{ReactHtmlParser(prodData.summary)}</div>
          <div>
            <Rating reviewContentRef={reviewContentRef} handleSetActiveTabAndScroll={handleSetActiveTabAndScroll} />
          </div>
          <div id='product-details-price-div' className='row'>
            {
              (Number(prodData.original_price) <= Number(prodData.actual_price)) ?
                '$' + prodData.original_price :
                (
                  <>
                    <label id='product-original-price-label'>${prodData.original_price}</label>
                    <label id='product-actual-price-label'>${prodData.actual_price}</label>
                  </>
                )
            }
            <div id='price-history-div' onClick={() => handleModalPriceProgress()}>
              <FaHistory />
            </div>
          </div>

          {
            (prodSpecs.length > 0) ?
              (
                <div id='product-details-specs-div'>
                  {prodSpecs.map((spec, index) => {
                    const button = (
                      <Button
                        variant={specButtonVariants[index]}
                        key={"spec" + spec.valeur}
                        id='product-details-specs-button'
                        size="sm"
                        onClick={() => handleSpecClick(index, spec.code, spec.valeur, spec.prod_photo)}
                      >
                        {spec.valeur}
                      </Button>
                    );
                    if (spec.code === specCodeTemp) {
                      currentLine.push(button);
                    } else {
                      const line = (
                        <div key={`line-${specCodeTemp}`}>
                          <span key={`code-${specCodeTemp}`}>
                            <div id='spec-code-temp'><label id='spec-code-temp-label'>{specCodeTemp}</label></div>
                            <div id='current-line'>{currentLine}</div>
                          </span>
                        </div>
                      );
                      currentLine = [button];
                      specCodeTemp = spec.code;
                      return line;
                    }
                  }
                  )}
                  {/* Render the last line */}
                  <div key={`line-${specCodeTemp}`}>
                    <span key={`code-${specCodeTemp}`}>
                      <div id='spec-code-temp'><label id='spec-code-temp-label'>{specCodeTemp}</label></div>
                      <div id='current-line'>{currentLine}</div>
                    </span>
                  </div>
                </div>
              ) :
              null
          }

          <div id='add-to-cart-div'>
            <div className='row' id='add-to-cart-div-row'>
              <div className="countHandlerInProductDetails">
                <button onClick={decrementQuantity} id='updateQuantityButton'> - </button>
                <input
                  value={valueOfInput}
                  onChange={(e) => {
                    const newQuantity = Number(e.target.value);
                    if (!isNaN(newQuantity) && newQuantity >= 0) {
                      setQuantity(newQuantity);
                    }
                  }}
                />
                <button onClick={incrementQuantity} id='updateQuantityButton'> + </button>
              </div>

              <button
                className="addToCartBttn"
                onClick={() => addToCart(prodData, valueOfInput, specSelectedArray, (Number(prodData.original_price) <= Number(prodData.actual_price)) ? Number(prodData.original_price) : Number(prodData.actual_price))}
              >
                Add To Cart
              </button>
            </div>
          </div>
          <Separator />

          {/* Frequently together div */}
          {
            freqBoughtTogCount > 0 ?
              (
                <div className='col-sm-12 col-md-12'>
                  <p>Frequently bought together :</p>
                  <div id='frequently-bought-div'>
                    {productRelateds.map((productRelated) => {
                      if (productRelated.frequently_bought_together === true) {
                        const product = products.find((produit) => produit.nom = productRelated.produit_nom)
                        const prodImages = JSON.parse(product.prod_images);

                        return (
                          <div className="productRelated grow" key={'alsoLike' + product.id} onClick={() => handleClick(product)}>
                            <div>
                              <img src={prodImages.length > 0 ? API_URL + prodImages[0].image : null} alt="" />
                            </div>

                            <div className="description">
                              <p>
                                <b>{product.nom}</b>
                              </p>
                              <p>
                                {
                                  (Number(product.original_price) <= Number(product.actual_price)) ?
                                    '$' + product.original_price :
                                    (
                                      <>
                                        <label id='product-original-price-label'>${product.original_price}</label>
                                        <label id='product-actual-price-label'>${product.actual_price}</label>
                                      </>
                                    )
                                }
                              </p>
                            </div>
                          </div>
                        )
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              ) : null
          }

          <div id='share-div'>
            <p>Share :</p>
            <FaFacebook size={35} />
            <FaTwitter size={35} id='fa-button-style' />
            <FaEnvelope size={35} id='fa-button-style' />
          </div>
        </div>
      </div>

      {/* Tab bloc */}
      <div className="tab-container">
        <Separator />
        {/* Tab bar header */}
        <div className="tab-bar">
          {tabContent.map((tab, index) => (
            <div
              key={'tabTitle' + index}
              className={`tab ${activeTab === index ? 'active' : ''}`}
              onClick={() => setActiveTab(index)}
            >
              {tab.title}
            </div>
          ))}
        </div>
        <Separator />
        {/* Tab content */}
        <div className="tab-content">
          {tabContent.map((tab, index) => (
            <div key={'tabContent' + index} className={`tab-pane ${activeTab === index ? 'active' : 'inactive'}`}>
              {tab.content}
            </div>
          ))}
        </div>
      </div>
      <div className='row' id='space-div'></div>

      {/* Price progression modal */}
      <Modal show={showModalPriceProgress} onHide={handleModalPriceProgress}>
        <Modal.Header closeButton>
          <Modal.Title>PRICE PROGRESSION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="align-items-center justify-content-center text-center">
            <div id='price-progression-div'>
              <label>Relative to the quantity rate : {prodData.price_progression_by_rate}</label>
              <div>
                {
                  priceProgressionByRate.map((item, index) => (
                    <div>
                      <ProgressBar value={Number(item.replace("%", ""))} max={100} />
                      <br></br>
                    </div>
                  ))
                }
              </div>
            </div>
            <div id='price-progression-div'>
              <label>Relative to the date : {prodData.price_progression_by_date}</label>
              <ProgressBar value={Number(prodData.price_progression_by_date.replace("%", ""))} max={100} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalPriceProgress}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
